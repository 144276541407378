import React, { useEffect, useState } from 'react'
import PlaceCard from '../Cards/PlaceCard'
import Counter from '../Counter/Counter'
import Pagination from '../Pagination/Pagination'
import SectionHeader from '../SectionHeader/SectionHeader'
import "./StayPlaces.css"

export default function StayPlaces() {
    const [count, setCount] = useState(1)
    const [count1, setCount1] = useState(0)
    const [count2, setCount2] = useState(3)
    const [show, setShow] = useState("")

    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(2200)

    const [stays, setStays] = useState([])

    //  pagination 
    const [filterPagination, setFilterPagination] = useState([])
    const [page, setPage] = useState(1)
    const [col, setCol] = useState(8)
    
    const pages = Math.ceil(stays.length / col)
    let pageArrey = Array(pages).fill(0)


    const getAllStays = async () => {

        const res = await fetch("https://airbnb-api-six.vercel.app/api/posts" , {
          method : "POST"
        })
        const data = await res.json()
        setStays(data.posts)
      }


    useEffect(() => {
        getAllStays()
    }, [])


    Pagination(stays,setFilterPagination, col, page)

    return (
        <div className='container my-3'>
            <div className='my-3'>
                <ul className='d-flex align-items-center flex-wrap'>
                    <li className={`mx-3 cursor-p position-relative mt-3 border px-3 py-2 rounded-50p`}
                        onClick={() => setShow("1")}>Type of place
                        {show === "1" &&
                            <div className='places-modal-box shadow'>
                                <button onClick={(e) => {
                                    e.stopPropagation()
                                    setShow("")
                                }}
                                    className='btn  float-end'>X</button>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Entire place
                                    <p className='parag fs-6'>Have a place to yourself</p>
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Private room
                                    <p className='parag fs-6'>Have a place to yourself</p>
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Hotel room
                                    <p className='parag fs-6'>Have a place to yourself</p>
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Shared room
                                    <p className='parag fs-6'>Have a place to yourself</p>
                                </div>
                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>
                        }
                    </li>
                    <li className={`mx-3 cursor-p position-relative mt-3 bg-personal px-3 py-2 rounded-50p `}
                        onClick={() => setShow("2")}>$0 - $1000
                        {show === "2" &&
                            <div className='places-modal-box shadow'>
                                <button onClick={(e) => {
                                    e.stopPropagation()
                                    setShow("")
                                }}
                                    className='btn  float-end'>X</button>
                                <div className='mb-3'>Price per day</div>
                                <div className='mb-3 d-flex'>
                                    <input value={minPrice} onChange={e => setMinPrice(e.target.value)}
                                    type="number" className='form-control w-50 me-1' placeholder='Min price' />
                                    <input value={maxPrice} onChange={e => setMaxPrice(e.target.value)}
                                    type="number" className='form-control w-50 ms-1' placeholder='Max price' />
                                </div>
                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button 
                                    className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>}</li>

                    <li className={`mx-3 cursor-p position-relative mt-3 border px-3 py-2 rounded-50p`}
                        onClick={() => setShow("3")}>Rooms of Beds
                        {show === "3" &&
                            <div className='places-modal-box shadow'>
                                <div className='d-flex'>
                                    <button onClick={(e) => {
                                        e.stopPropagation()
                                        setShow("")
                                    }}
                                        className='btn  ms-auto'>X</button>
                                </div>
                                <div className='mb-3 d-flex align-items-center'>
                                    <span className='me-auto'>Beds</span>
                                    <Counter className="w-50 " count={count} setCount={setCount} />
                                </div>
                                <div className='mb-3 d-flex align-items-center'>
                                    <span className='me-auto'>Bedrooms</span>
                                    <Counter className="w-50" count={count1} setCount={setCount1} />
                                </div>
                                <div className='mb-3 d-flex align-items-center'>
                                    <span className='me-auto'>Bathrooms</span>
                                    <Counter className="w-50" count={count2} setCount={setCount2} />
                                </div>

                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button 
                                    className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>}</li>

                    <li className={`mx-3 cursor-p position-relative mt-3 bg-personal px-3 py-2 rounded-50p`}
                        onClick={() => setShow("4")}>More filters (3)
                        {show === "4" &&
                            <div className='places-modal-box shadow'>
                                <button onClick={(e) => {
                                    e.stopPropagation()
                                    setShow("")
                                }}
                                    className='btn  float-end'>X</button>
                                <div className='mb-3'>
                                    <h3 className='h4'>Amenities</h3>
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-2' />
                                    Kitchen
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-2' />
                                    Air conditioning
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-2' />
                                    Heating
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-2' />
                                    Wifi
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-2' />
                                    Washer
                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-2' />
                                    Pool
                                </div>
                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>}</li>
                            <li className={`mx-3 cursor-p position-relative mt-3 px-3 py-2 rounded-50p ms-auto`}>
                            <select  onChange={(e) => setCol(e.target.value)}
                            className='form-select'>
                                <option>Per page</option>
                                <option>2</option>
                                <option>4</option>
                                <option>8</option>
                            </select>
                            
                            </li>
                </ul>
            </div>
            {filterPagination.length ? (
            <div className='d-flex align-items-center justify-content-between flex-wrap'>
                {filterPagination.map(stay => (
                    <PlaceCard {...stay} key={stay._id}/>
                ))}    
            </div>) : (
                <div className='alert alert-danger'>No item defined</div>
            )}

            <div className='d-flex justify-content-center align-items-center p-5'>
                <div>
                {pageArrey.map((num, index) => (
                        <span key={index} onClick={() => setPage(index + 1)}
                        className={`cursor-p p-2 border rounded-50p  me-2 ${page == index +1 && "bg-personal"}`}>{index + 1}</span>
                    ))}
                   
                </div> 
            </div>
        </div>
    )
}
