import React, { useState } from 'react'
import FlightCard from '../Cards/FlightCard'
import RangeInput from '../RangeInput/RangeInput'



export default function StayPlaces() {
    const [count, setCount] = useState(1)
    const [show, setShow] = useState("")
    const [radioBtn, setRadioBtn] = useState("1")
    const [firstHour, setFirstHour] = useState(0)
    const [secondHour, setSecondFirstHour] = useState(0)

    return (
        <div className='container my-3'>
            <div className='my-3'>

                <ul className='d-flex align-items-center row flex-wrap'>
                    <li className={` cursor-p position-relative col-xl-2 col-lg-2 col-md-5 col-12`}
                        onClick={() => setShow("1")}>
                            <div className='mx-2 mb-2 border rounded-50p d-flex align-items-center px-3 py-2'>
                            Airlines
                        <span className="material-symbols-outlined ms-auto cursor-p ms-3">
                            keyboard_arrow_down
                        </span>
                        {show === "1" &&
                            <div className='places-modal-box shadow'>
                                <button onClick={(e) => {
                                    e.stopPropagation()
                                    setShow("")
                                }}
                                    className='btn  float-end'>X</button>
                                <div className='mb-4 py-3 border-bottom'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    All Airlines

                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Star Airlin

                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Air China

                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Air India

                                </div>
                                <div className='mb-4'>
                                    <input type="checkbox" className='form-check-input me-1' />
                                    Asiana

                                </div>
                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>
                        }
                        </div>
                    </li>

                    <li className={` cursor-p position-relative col-xl-3 col-lg-3 col-md-5 col-12`}
                        onClick={() => setShow("3")}>
                            <div className='mx-2 mb-2 border rounded-50p d-flex align-items-center px-3 py-2'>
                            Less than 10 hours
                        {show === "3" &&
                            <div className='places-modal-box shadow'>
                                <div className='d-flex'>
                                    <button onClick={(e) => {
                                        e.stopPropagation()
                                        setShow("")
                                    }}
                                        className='btn  ms-auto'>X</button>
                                </div>
                                <div className='mb-3 '>
                                    <span className='mb-3'>Trip time: <span className='text-primary'> {`<10 hours`}</span></span>
                                </div>
                                <div className='my-4'>
                                    <RangeInput />
                                </div>

                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>}
                            </div>
                    </li>

                    <li className={` cursor-p position-relative col-xl-3 col-lg-3 col-md-5 col-12`}
                        onClick={() => setShow("4")}>
                            <div className='mx-2 mb-2 border rounded-50p d-flex align-items-center px-3 py-2'>
                            Flight time
                        <span className="material-symbols-outlined ms-auto cursor-p ms-3">
                            keyboard_arrow_down
                        </span>
                        {show === "4" &&
                            <div className='places-modal-box shadow'>
                                <button onClick={(e) => {
                                    e.stopPropagation()
                                    setShow("")
                                }}
                                    className='btn  float-end'>X</button>
                                <div className='mb-3'>
                                    <h3 className='h4'>Amenities</h3>
                                </div>

                                <div className='row text-center text-primary my-4 border rounded-25p bg-light'>
                                    <div onClick={() => setRadioBtn("1")}
                                        className={radioBtn === "1" ? 'col-6 p-3 border rounded-25p bg-white' : "col-6 p-3"}
                                    >Take off</div>
                                    <div onClick={() => setRadioBtn("2")}
                                        className={radioBtn === "2" ? 'col-6 p-3 border rounded-25p bg-white' : "col-6 p-3"}
                                    >Landing</div>
                                </div>
                                <div className='my-3 bg-light rounded-25p p-2'>
                                    {radioBtn === "1" ? (
                                        <p className='parag my-3'>Singapore to Tokyo</p>) : (
                                        <p className='parag my-3'>Tokyo to Singapore</p>)}
                                    <div className='mt-5 mb-3'>
                                        <span>
                                            Departure time: {firstHour}:00</span>
                                        <div className="range-slider">
                                            <input value={firstHour} onChange={e => setFirstHour(e.target.value)} min="0" max="24" step="1" type="range" />
                                            <svg width="100%" height="24">
                                                <line x1="4" y1="0" x2="300" y2="0" stroke="#444" stroke-width="12" stroke-dasharray="1 28"></line>
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                        <span>Arrival time: {secondHour}:00</span>
                                        <div className="range-slider">
                                            <input value={secondHour} onChange={e => setSecondFirstHour(e.target.value)} min="0" max="24" step="1" type="range" />
                                            <svg width="100%" height="24">
                                                <line x1="4" y1="0" x2="300" y2="0" stroke="#444" stroke-width="12" stroke-dasharray="1 28"></line>
                                            </svg>
                                        </div>
                                    </div>

                                </div>


                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>}
                            </div>
                    </li>

                    <li className={` cursor-p position-relative col-xl-2 col-lg-2 col-md-5 col-12`}
                        onClick={() => setShow("2")}>
                            <div className='mx-2 mb-2 border rounded-50p d-flex align-items-center px-3 py-2'>
                            $0 - $1000
                        {show === "2" &&
                            <div className='places-modal-box shadow'>
                                <button onClick={(e) => {
                                    e.stopPropagation()
                                    setShow("")
                                }}
                                    className='btn  float-end'>X</button>
                                <div className='mb-3'>Price per day</div>
                                <div className='mb-3 d-flex'>
                                    <input type="number" className='form-control w-50 me-1' placeholder='Min price' />
                                    <input type="number" className='form-control w-50 ms-1' placeholder='Max price' />
                                </div>
                                <div className='py-3 d-flex border-top'>
                                    <button className='btn btn-secondary'>Clear</button>
                                    <button className='btn btn-primary ms-auto'>Apply</button>
                                </div>
                            </div>}
                            </div>
                    </li>

                    <li className={` cursor-p position-relative col-xl-2 col-lg-2 col-md-5 col-12`}
                        onClick={() => setShow("5")}>
                            <div className='mx-2 mb-2 border rounded-50p d-flex align-items-center px-3 py-2'>
                            On sale
                        <span className="material-symbols-outlined ms-auto cursor-p ms-3">
                            close
                        </span>
                        </div>
                    </li>

                </ul>
            </div>
            <div className='container py-3 rounded-25p bg-light'>
                <FlightCard />
                <FlightCard />
                <FlightCard />
                <FlightCard />
                <FlightCard />
                <FlightCard />
            </div>
            <div className='d-flex justify-content-center align-items-center p-5'>
                <div>
                    <span className='cursor-p p-2 border rounded-50p bg-personal me-2'>1</span>
                    <span className='cursor-p p-2 border rounded-50p bg-personal me-2'>2</span>
                    <span className='cursor-p p-2 border rounded-50p bg-personal me-2'>3</span>
                    <span className='cursor-p p-2 border rounded-50p bg-personal me-2'>4</span>
                </div>
            </div>
        </div>
    )
}
