
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./SliderExplore.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import CityCard from "../Cards/CityCard";

export default function SliderExplore() {
  return (
    <div className="container text-start">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          540: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          868: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1124: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
          1424: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        
        modules={[Pagination]}
        className="mySwiper"
        style={{
          width: '100%',
          height: 320,
        }}
      >
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s1.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s2.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s3.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s4.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s5.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s3.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s6.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s4.jpg" /></SwiperSlide>
        <SwiperSlide className="swiper-slide-slider-explore-img"><CityCard img="/img/s7.jpg" /></SwiperSlide>
      </Swiper>
    </div>
  );
}
