import React from 'react'
import { Link } from 'react-router-dom'



export default function CarBox({ img, title, off, price, rate, _id }) {
    return (
        <div className='col-xl-3 col-lg-4 col-md-6 p-3 col-sm-12 ' style={{ overflow: "hidden" }}>
            <Link to={`/carinfo/${_id}`}>
                <div className="card border-none shadow rounded-25p">
                    <img src={`${img}`} className="rounded-25p" style={{ width: "100%" }} />

                    <span className="material-symbols-outlined place-card-fav-icon">
                        favorite
                    </span>
                    {off &&
                        <button className={`btn btn-danger w-25  btn-sm  rounded-50p place-card-off-btn`}>
                            {`-${off}% today`}</button>}
                    <div className="card-body">
                        <h5 className="card-title my-2 h5">{title}</h5>
                        <p className='parag'>{4} seats - Auto gearbox</p>
                    </div>
                    <div className='p-3 d-flex align-items-center'>
                        <div className="p-bold">${price}</div>
                        <div className='small parag me-auto'>/day</div>
                        <div><span className="material-symbols-outlined text-warning">
                            star
                        </span></div>
                        <div className='mx-1 p-bold'>{4.8}</div>
                        <div className='small parag'>(113)</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
