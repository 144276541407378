import React from 'react'

export default function SectionHeader({ title, subTitle }) {
    return (
        <div className='container my-5'>
            <h3 className='h1'>{title}</h3>
            <h5 className='parag'>{subTitle}</h5>
        </div>
    )
}
