import React, { useEffect, useState } from 'react'

export function Toast({ show, title }) {

    return (
        <>
            {show && (
                <div className='bottom-0 end-0 position-fixed p-4 z-3'>
                    <div className="toast align-items-center text-bg-primary border-0 show " >
                        <div className="d-flex">
                            <div className="toast-body">
                                {title}
                            </div>
                            <button type="button" className="btn-close btn-close-white me-2 m-auto" ></button>
                        </div>
                    </div>
                </div>)}
        </>
    )
}


