import React, { useEffect, useState } from 'react'
import Counter from '../Counter/Counter'
import StaysModal from '../Modals/StaysModal'

export default function Stays() {

    const [stays, setStays] = useState([])
    const [staySearch, setStaySearch] = useState([])
    const [showLocationModal, setShowLocationModal] = useState(true)
    const [showGuestsModal, setShowGuestsModal] = useState(false)
    const [ticketInfo, setTicketInfo] = useState({location : "" , checkIn:"",checkOut:""})
    const [count, setCount] = useState(0)
    const [count1, setCount1] = useState(1)
    const [count2, setCount2] = useState(0)

    const getAllStays = async () => {
        const res = await fetch("https://airbnb-api-six.vercel.app/api/posts" , {
            method : "POST"
          })
          const data = await res.json()
          setStays(data.posts)
    }

    const searchLocation = (e) => {
        setShowLocationModal(true)
        let data = stays.filter(stay => {
            return stay.country.toUpperCase().includes((e.target.value).toUpperCase())
        })
        setStaySearch(data)
        console.log(data);
    }

    const changeHandler = (e) => {
        setTicketInfo(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }


    useEffect(() => {
        let now = new Date()

        setTicketInfo(prev => {
            return {
                ...prev,
                checkIn: now.toISOString().slice(0, 10),
                checkOut: now.toISOString().slice(0, 10)
            }
        })
    }, [])


    return (
        <>
        <div className='row shadow rounded-50p bg-white' onClick={() => setShowGuestsModal(false)}>
            <div className='col-xl-3 col-lg-6 col-sm-12'>
                <div className='d-flex p-4 align-items-center'>
                    <span className="material-symbols-outlined parag fs-2">
                        location_on
                    </span>
                    <div className='position-relative'>
                        <input onChange={e => {
                            changeHandler(e)
                            searchLocation(e)
                        }} onFocus={getAllStays} value={ticketInfo.location} name="location"
                            type="text" className='form-control border-none place' placeholder='Location' />
                        <p className='parag cursor-p p-2 small'>Where are you going</p>
                        {showLocationModal &&
                            <div className='position-absolute bg-white shadow rounded-25p top-100 z-2' style={{ width: 270 }}>
                                {staySearch.map(stay => (
                                    <p key={stay._id} onClick={() => {
                                        setTicketInfo(prev => {
                                            return {
                                                ...prev,
                                                location:`${stay.country}` 
                                            }
                                        })
                                        setShowLocationModal(false)
                                    }}
                                        className='py-2 m-2 cursor-p'>{stay.country}</p>
                                ))}
                            </div>}
                    </div>
                </div>

            </div>
            <div className='col-xl-3 col-lg-6 col-sm-12'>
                <div className='d-flex p-4 align-items-center'>
                    <span className="material-symbols-outlined parag fs-2">
                        calendar_today
                    </span>
                    <div className=' position-relative'>
                        <input value={ticketInfo.checkIn} onChange={changeHandler} name="checkIn"
                            type="date" className='form-control border-none place check-in' />
                        <p className='parag cursor-p p-2 small'>Add date</p>
                    </div>
                </div>
            </div>
            <div className='col-xl-3 col-lg-6 col-sm-12'>
                <div className='d-flex p-4 align-items-center'>
                    <span className="material-symbols-outlined parag fs-2">
                        calendar_today
                    </span>
                    <div className=' position-relative'>
                        <input name="checkOut" onChange={changeHandler} value={ticketInfo.checkOut}
                            type="date" className='form-control border-none place check-out' />
                        <p className='parag cursor-p p-2 small'>Add date</p>
                    </div>
                </div>
            </div>
            <div className='col-xl-2 col-lg-6 col-sm-12'>
                <div onClick={(e) => {
                    e.stopPropagation()
                    setShowGuestsModal(true)}}
                className='d-flex p-4 align-items-center cursor-p'>
                    <span className="material-symbols-outlined parag fs-2">
                        person_add
                    </span>
                    <div 
                    className='position-relative'>
                        <div className='form-control border-none p-bold' >{count + count1 + count2 } Guests</div>
                        <p className='parag cursor-p p-2 small'>Add guests</p>

                        {showGuestsModal &&
                            <div className='position-absolute bg-white shadow rounded-25p top-100 left-0 z-3' style={{ width: 350 }}>
                                <div className='d-flex align-items-center px-3 py-2'>
                                    <div>
                                        <p>Adults</p>
                                        <p className='small parag'>Ages 13 or above</p>
                                    </div>
                                    <Counter count={count} setCount={setCount} className="ms-auto" />
                                </div>
                                <div className='d-flex align-items-center px-3 py-2'>
                                    <div>
                                        <p>Children</p>
                                        <p className='small parag'>Ages 2–12</p>
                                    </div>
                                    <Counter count={count1} setCount={setCount1} className="ms-auto" />
                                </div>
                                <div className='d-flex align-items-center px-3 py-2'>
                                    <div>
                                        <p>Infants</p>
                                        <p className='small parag'>Ages 0–2</p>
                                    </div>
                                    <Counter count={count2} setCount={setCount2} className="ms-auto" />
                                </div>

                            </div>}
                    </div>
                </div>
            </div>
            <div className='col-xl-1 col-lg-12 col-sm-12'>
                <div className='d-flex p-4  align-items-center justify-content-center'>
                    <button className='btn btn-primary my-auto rounded-50 '>
                        <span className="material-symbols-outlined text-white fs-2 p-1">
                            search
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <StaysModal country={ticketInfo.location} /> 
        </>
    )
}
