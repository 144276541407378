import React from 'react'

export default function Experiences() {
  return (
    <div className='row shadow rounded-50p bg-white'>
                <div className='col-md-5 col-sm-12'>
                    <div className='d-flex p-4 align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                            location_on
                        </span>
                        <div>
                            <input type="text" className='form-control border-none place' placeholder='Location' />
                            <p className='parag cursor-p p-2'>Where are you going</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-12'>
                    <div className='d-flex p-4 align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                        calendar_today
                        </span>
                        <div className=' position-relative'>
                            <input type="date" className='form-control border-none place input-date'  />
                            <p className='parag cursor-p p-2'>Add date</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-12'>
                    <div className='d-flex p-4 align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                            person_add
                        </span>
                        <div>
                            <div className='form-control border-none p-bold' >Guests</div>
                            <p className='parag cursor-p p-2'>Add guests</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-1 col-sm-12'>
                    <div className='d-flex p-4  align-items-center justify-content-center'>
                        <button className='btn btn-primary my-auto rounded-50 '>
                            <span className="material-symbols-outlined text-white fs-2 p-1">
                                search
                            </span>
                        </button>
                    </div>
                </div>
            </div>
  )
}
