import About from "./Pages/About/About";
import Account from "./Pages/Account/Account";
import AddListing from "./Pages/AddListing/AddListing";
import Blog from "./Pages/Blog/Blog";
import BlogSingle from "./Pages/BlogSingle/BlogSingle";
import Cars from "./Pages/Cars/Cars";
import CarSingle from "./Pages/Cars/CarSingle";
import Contact from "./Pages/Contact/Contact";
import FlightsLists from "./Pages/FlightsLists/FlightsLists";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Peyment from "./Pages/Peyment/Peyment";
import Stay from "./Pages/Stay/Stay";
import StayInfo from "./Pages/StayInfo/StayInfo";
import Subscription from "./Pages/Subscription/Subscription";


export const routes = [
    {path:"/" , element:<Home />},
    {path:"/login" , element:<Login />},
    {path:"/stay" , element:<Stay />},
    {path:"/cars" , element:<Cars />},
    {path:"/flights" , element:<FlightsLists />},
    {path:"/peyment" , element:<Peyment />},
    {path:"/blog" , element:<Blog />},
    {path:"/about" , element:<About />},
    {path:"/contact" , element:<Contact />},
    {path:"/account" , element:<Account />},
    {path:"/add-listing" , element:<AddListing />},
    {path:"/subscription" , element:<Subscription />},
    {path:"/stayinfo/:infoId" , element:<StayInfo />},
    {path:"/carinfo/:infoId" , element:<CarSingle />},
    {path:"/blog-single/:infoId" , element:<BlogSingle />},
]