import React, { useState } from 'react'
import Counter from '../Counter/Counter'

export default function Flights() {
    const [count , setCount] = useState(1)
    const [showCounter , setShowCounter] = useState(false)
    const [Way , setWay] = useState("round")

    return (
        <>
            <div onClick={() => setShowCounter(false)}
            className='p-2 d-flex flex-wrap '>
                <button onClick={() => setWay("round")}
                className={`btn btn-outline-dark px-3 btn-sm me-3 rounded-50p mb-2 ${Way === "round" && "active"}`}>
                    Round-trip</button>
                <button onClick={() => setWay("one")}
                className={`btn btn-outline-dark px-3 btn-sm me-3 rounded-50p mb-2 ${Way === "one" && "active"}`}>one-way</button>
                <button className='btn btn-outline-dark px-3 btn-sm me-3 rounded-50p mb-2 align-items-center d-flex'>
                    <span>Economy</span>     
                    <span className="material-symbols-outlined">
                        expand_more
                    </span>
                </button>
                <button onClick={e => {
                    e.stopPropagation()
                    setShowCounter(true)} }
                className='btn btn-outline-dark px-3 btn-sm rounded-50p mb-2 align-items-center d-flex position-relative'>
                    {count} Guest
                    <span className="material-symbols-outlined">
                        expand_more
                    </span>
                    {showCounter &&
                    <div className='flights-counter-div'>
                        <Counter count={count} setCount={setCount}/>
                    </div>}
                    
                </button>
                
            </div>
            <div className='row shadow rounded-50p bg-white'>
                <div className='col-md-3 col-sm-12'>
                    <div className='d-flex py-3 align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                            location_on
                        </span>
                        <div>
                            <input type="text" className='form-control border-none place' placeholder='Flying from' />
                            <p className='parag cursor-p p-2 small'>Where do you want to fly...</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-2 col-sm-12'>
                    <div className='d-flex py-3 align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                            location_on
                        </span>
                        <div>
                            <input type="text" className='form-control border-none place' placeholder='Flying to' />
                            <p className='parag cursor-p p-2 small'>You want to fly to?</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-12'>
                    <div className='d-flex py-3 align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                            calendar_today
                        </span>
                        <div className=' position-relative'>
                            <input type="date" className='form-control border-none place check-in' />
                            <p className='parag cursor-p p-2 small'>Add date</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3 col-sm-12'>
                    <div className='d-flex py-3 align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                            calendar_today
                        </span>
                        <div className=' position-relative'>
                            <input type="date" className='form-control border-none place check-out' />
                            <p className='parag cursor-p p-2 small'>Add date</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-1 col-sm-12'>
                    <div className='d-flex p-4  align-items-center justify-content-center'>
                        <button className='btn btn-primary my-auto rounded-50 '>
                            <span className="material-symbols-outlined text-white fs-2 p-1">
                                search
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
