import React, { useEffect, useState } from 'react'
import { useReducer } from 'react'
import validator from '../../Validations/validator'


const reducer = (state, action) => {
    switch (action.type) {
        case "INPUT": {
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        } default: return {
            ...state
        }
    }
}


export default function Input({ type, className, id, validations, inputHandler }) {
    const [error , setError] = useState(false)
    const [inputState, dispatch] = useReducer(reducer,
        {
            value: "",
            isValid: false
        })

    const onChangeHandler = (e) => {
        dispatch({
            type: "INPUT",
            value: e.target.value,
            isValid: validator(e.target.value, validations)
        })
    }

    useEffect(() => {
        inputHandler(id, inputState.value, inputState.isValid)
         if(inputState.isValid){
            setError(false)
        }
    }, [inputState , inputState.value , id])

    const blurInput = () => {
        if(!inputState.isValid){
            setError(true)
        }
    }

    return (
        <div className='d-flex position-relative'>
            <input type={type} className={className} value={inputState.value} onChange={e => onChangeHandler(e)} onBlur={blurInput}/>
            {inputState.isValid && <span className="material-symbols-outlined text-success position-absolute end-0 bottom-25">
                check
            </span>}
            {error &&
              <span className="material-symbols-outlined text-danger position-absolute end-0 bottom-25">
                close
            </span>}
            
        </div>
    )
}
