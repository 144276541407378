import React from 'react'
import CommentBox from '../../Components/CommentBox/CommentBox'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import SocialIcon from '../../Components/SocialIcon/SocialIcon'
import TagBox from '../../Components/TagBox/TagBox'
import Post from '../../Components/PostBox/post'

export default function BlogSingle() {
    return (
        <>
            <Header />
            <div className='container'>
                <div className='col-lg-8 col-xs-12 mx-auto py-5 px-3'>
                    <h1 className='h1'>Keep up the spirit of the desire to travel around the world</h1>
                    <p className='parag fs-5 my-4 border-bottom py-3'>We’re an online magazine dedicated to covering the best in international product design.
                        We started as a little blog back in 2002 covering student work and over time
                    </p>
                    <div className='d-flex mb-4'>
                        <img src='/img/f3.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3" />
                        <div >
                            <p className='p-bold'>Fones Mimi</p>
                            <p className='parag'>May 20,2021 . 6 min read</p>
                        </div>
                        <div className='d-flex ms-auto '>
                            <SocialIcon icon="facebook" />
                            <SocialIcon icon="twitter" />
                            <SocialIcon icon="youtube" />
                            <SocialIcon icon="instagram" />
                        </div>
                    </div>

                </div>
                <div>
                    <img src="/img/f4.jpg" />
                </div>
                <div className='col-lg-8 col-xs-12 mx-auto py-5 px-3'>
                    <p className='parag fs-5 my-4  py-3'>We’re an online magazine dedicated to covering the best in international product design.
                        We started as a little blog back in 2002 covering student work and over time itaque neque dolorem modi perspiciatis
                        dolor distinctio veritatis sapiente, minima corrupti
                        dolores necessitatibus suscipit accusantium dignissimos culpa cumque.
                    </p>
                    <p className='parag fs-5 my-4  py-3'>We’re an online magazine dedicated to covering the best in international product design.
                        We started as a little blog back in 2002 covering student work and over time
                        itaque neque dolorem modi perspiciatis dolor distinctio veritatis sapiente, minima corrupti dolores necessitatibus
                        suscipit accusantium dignissimos culpa cumque.
                    </p>
                    <p className='parag fs-5 my-1  py-3'>
                        1. We want everything to look good out of the box.
                    </p>
                    <p className='parag fs-5 my-1  py-3'>
                        2. Really just the first reason, that's the whole point of the plugin.
                    </p>
                    <p className='parag fs-5 my-1  py-3'>
                        3. Here's a third pretend reason though a list with three items looks more realistic than a list with two items.
                    </p>
                    <h4 className='h4'>Typography should be easy</h4>
                    <p className='parag fs-5 my-2  py-3'>the best in international product design.
                        We started as a little blog back in 2002 covering student work and over time
                        itaque neque dolorem modi perspiciatis dolor distinctio veritatis sapiente, minima corrupti dolores necessitatibus
                        suscipit accusantium dignissimos culpa cumque.
                    </p>
                    <p className='parag fs-5 my-2  py-3'>
                        Something a wise person once told me about typography is:
                    </p>
                    <div>
                        <img src="/img/f3.jpg" />
                        <p className='parag fs-5 my-2  py-3'>
                            We started as a little blog back in 2002 covering student work and over time
                            itaque neque dolorem modi perspiciatis dolor distinctio veritatis sapiente, minima corrupti dolores necessitatibus
                            suscipit accusantium dignissimos culpa cumque.
                        </p>
                        <p className='parag fs-5 my-2  py-3'>
                            Now I'm going to show you an example of an unordered list to make sure that looks good, too:
                        </p>
                        <div className=' py-3'>
                            <span className='borderd py-2 px-3 cursor-p border rounded parag me-2'>Garden</span>
                            <span className='borderd py-2 px-3 cursor-p border rounded parag me-2'>Jewelry</span>
                            <span className='borderd py-2 px-3 cursor-p border rounded parag me-2'>Tools</span>
                        </div>
                        <hr />
                        <div className='d-flex mb-4 align-items-center'>
                            <img src='/img/f3.jpg' style={{ width: 100, height: 100 }} className="rounded-50p me-3" />
                            <div >
                                <p className='parag'>WRITEN BY</p>
                                <p className='p-bold my-2'>Fones Mimi</p>
                                <p className='parag fs-6 col-md-10 col-sm-12 d-none-600'>There’s no stopping the tech giant. Apple now opens its 100th store in
                                    China.There’s no stopping the tech giant.Readmore
                                </p>
                            </div>
                        </div>
                    </div>
                        <CommentBox />

                </div>
                
            </div>
            <div className='p-5 bg-light'>
                    <h2 className='h2 my-4'>Related posts</h2>
                    <div className='row'>
                        <Post img="/img/s5.jpg" title="Lenovo smarter devices stoke professional passions"/>
                        <Post img="/img/s4.jpg" title="Excellent, congratulations on completing the listing"/>
                        <Post img="/img/s3.jpg" title="Shorter trips can mean more reservations, but you"/>
                        <Post img="/img/s4.jpg" title="the listing, it is waiting to be reviewed for publication"/>
                    </div>
                </div>
            <Footer />
        </>
    )
}
