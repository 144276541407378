import React, { useRef, useState } from 'react'
import "./RangeInput.css"

export default function RangeInput() {

    const [value , setValue] = useState(0)

    return (
        <div>
            <span>{value }</span>
            <div className="range-slider">
                <input value={value} onChange={e => setValue(e.target.value)} min="0" max="10" step="1" type="range" />       
                <svg width="100%" height="24">
                    <line x1="4" y1="0" x2="300" y2="0" stroke="#444" stroke-width="12" stroke-dasharray="1 28"></line>
                </svg>
            </div>
        </div>
    )
}
