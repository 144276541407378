import React, { useEffect, useState } from 'react'
import CarBox from '../CarsBoxes/CarBox'


export default function CarsModal({ country }) {

    const [cars, setCars] = useState([])

    const getAllcars = async () => {
        const res = await fetch("https://airbnb-api-six.vercel.app/api/posts", {
            method: "POST"
        })
        const data = await res.json()

        setCars(data.posts)
    }

    useEffect(() => {
        getAllcars()
    }, [country])

    return (
        <div className='container my-3 mx-auto'>
            <div className='d-flex align-items-center justify-content-between flex-wrap'>
                {cars.map(car => <CarBox {...car} key={car._id} />)}
            </div>
        </div>
    )
}
