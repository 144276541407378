import React, { useState } from 'react'
import CarsModal from '../Modals/CarsModal'

export default function CarsSelect() {

    const [value, setValue] = useState("same")

    const [carInfo, setCarInfo] = useState({ pick: "", drop: "" })
    const [cars, setCars] = useState([])
    const [carSearch, setCarSearch] = useState([])

    const getAllcars = async () => {
        const res = await fetch("https://airbnb-api-six.vercel.app/api/posts", {
            method: "POST"
        })
        const data = await res.json()
        setCars(data.posts)
    }

    const changeHandler = (e) => {
        setCarInfo(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    return (<>
        <div className='p-2 '>
            <button onClick={() => setValue("same")}
                className={`btn btn-outline-dark px-3 btn-sm me-3 rounded-50p ${value === "same" && 'active'}`}>Same drop off</button>
            <button onClick={() => setValue("different")}
                className={`btn btn-outline-dark px-3 btn-sm me-3 rounded-50p ${value === "different" && 'active'}`}>Different drop off</button>
        </div>
        <div className='row shadow rounded-50p bg-white'>
            <div className='col-md-3 col-sm-12'>
                <div className='d-flex p-3 align-items-center'>
                    <span className="material-symbols-outlined parag fs-2">
                        location_on
                    </span>
                    <div>
                        <input name="pick" onChange={changeHandler} onFocus={getAllcars} value={carInfo.pick}
                            type="text" className='form-control border-none place' placeholder='City or Airport' />
                        <p className='parag cursor-p p-2 small'>Pick up location</p>
                    </div>
                </div>
            </div>
            <div className='col-md-2 col-sm-12'>
                {value === "different" &&
                    <div className='d-flex py-3  align-items-center'>
                        <span className="material-symbols-outlined parag fs-2">
                            location_on
                        </span>
                        <div>
                            <input name="drop" onChange={changeHandler} value={carInfo.drop}
                                type="text" className='form-control border-none place' placeholder='City or Airport' />
                            <p className='parag cursor-p p-2 small'>Drop off location</p>
                        </div>
                    </div>}
            </div>
            <div className='col-md-3 col-sm-12'>
                <div className='d-flex p-4 align-items-center'>
                    <span className="material-symbols-outlined parag fs-2">
                        calendar_today
                    </span>
                    <div className=' position-relative'>
                        <input type="date" className='form-control border-none place pick-up' />
                        <p className='parag cursor-p p-2 small'>Add date</p>
                    </div>
                </div>
            </div>
            <div className='col-md-3 col-sm-12'>
                <div className='d-flex p-4 align-items-center'>
                    <span className="material-symbols-outlined parag fs-2">
                        calendar_today
                    </span>
                    <div className=' position-relative'>
                        <input type="date" className='form-control border-none place drop-off' />
                        <p className='parag cursor-p p-2 small'>Add date</p>
                    </div>
                </div>
            </div>

            <div className='col-md-1 col-sm-12'>
                <div className='d-flex p-4  align-items-center justify-content-center'>
                    <button className='btn btn-primary my-auto rounded-50 '>
                        <span className="material-symbols-outlined text-white fs-2 p-1">
                            search
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <CarsModal country={carInfo.pick} />
    </>
    )
}
