import React from 'react'

export default function SocialIcon({href , title , icon}) {
  return (
    <div href={href} className="d-flex align-items-center my-2 cursor-p">
        <i className={`fa fa-${icon} icon-hover border p-1`}></i>
        <p className='parag ms-2'>{title}</p>
    </div>
  )
}
