import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SocialIcon from '../SocialIcon/SocialIcon'
import "./Modals.css"

export default function MenuModal({ setShowMainMenu, showMainMenu }) {

    const [show, setShow] = useState("0")
    const [showLan, setShowLan] = useState(false)

    return (
        <div className={showMainMenu ? `menu-modal-container col-xs-12 col-sm-9 col-md-6 col-lg-4  right-position-0` : `menu-modal-container `}>
            <div className='' onClick={() => setShowLan("")}>
                <div className='d-flex'>
                    <img src='/img/logo.png' style={{width:100}} />
                    <button onClick={() => setShowMainMenu("")}
                        className=' btn-close ms-auto'></button>
                </div>
                <p className='parag my-3 fs-6'>Discover the most outstanding articles on all topics of life.

                </p>
                <div className='d-flex align-items-center fs-6 border-bottom'>
                    <div className='d-flex my-2 '>
                        <SocialIcon icon="facebook" />
                        <SocialIcon icon="twitter" />
                        <SocialIcon icon="youtube" />
                        <SocialIcon icon="instagram" />
                    </div>
                    <div className='ms-auto'>
                        <span className="material-symbols-outlined  btn icon-hover">
                            light_mode
                        </span>
                    </div>
                </div>
                <div className='border-bottom'>
                    <div className='d-flex align-items-center fs-6 mt-3 mb-4'>
                        <Link to="/">
                            <span className='fs-6'>Home</span>
                        </Link>
                    </div>
                    <div onClick={() => setShow(prev => {
                        if (prev === "0") {
                            return "4"
                        } else {
                            return "0"
                        }
                    })}
                        className='d-flex align-items-center mt-4 mb-2'>

                        <span className='fs-6'>Stay</span>

                        <span className="material-symbols-outlined ms-auto cursor-p">
                            expand_more
                        </span>
                    </div>
                    {show === "4" &&
                        <div className='bg-light p-2 rounded'>
                            <div className='d-flex align-items-center fs-6 my-2'>
                            <Link to="/stay">
                                    <span className=''>Stay</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-3'>
                                <Link to="/about">
                                    <span className=''>About</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-2'>
                                <Link to="/contact">
                                    <span className=''>Contact</span>
                                </Link>
                            </div>
                        </div>}
                    
                    <div onClick={() => setShow(prev => {
                        if (prev === "0") {
                            return "3"
                        } else {
                            return "0"
                        }
                    })}
                        className='d-flex align-items-center mt-4 mb-2'>

                        <span className='fs-6'>Flights</span>

                        <span className="material-symbols-outlined ms-auto cursor-p">
                            expand_more
                        </span>
                    </div>
                    {show === "3" &&
                        <div className='bg-light p-2 rounded'>
                            <div className='d-flex align-items-center fs-6 my-2'>
                            <Link to="/flights">
                                    <span className=''>Flights</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-3'>
                                <Link to="/about">
                                    <span className=''>About</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-2'>
                                <Link to="/contact">
                                    <span className=''>Contact</span>
                                </Link>
                            </div>
                        </div>}
                    <div className='d-flex align-items-center fs-6 my-3'>
                        <Link to="/peyment">
                            <span className='fs-6'>Peyment</span>
                        </Link>
                    </div>

                    <div className='d-flex align-items-center fs-6 my-3'>
                        <Link to="/subscription">
                            <span className='fs-6'>Subscription</span>
                        </Link>
                    </div>
                    
                    <div onClick={() => setShow(prev => {
                        if (prev === "0") {
                            return "2"
                        } else {
                            return "0"
                        }
                    })}
                        className='d-flex align-items-center mt-4 mb-2'>

                        <span className='fs-6'>Car</span>

                        <span className="material-symbols-outlined ms-auto cursor-p">
                            expand_more
                        </span>
                    </div>
                    {show === "2" &&
                        <div className='bg-light p-2 rounded'>
                            <div className='d-flex align-items-center fs-6 my-2'>
                            <Link to="/cars">
                                    <span className=''>Car</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-3'>
                                <Link to="/about">
                                    <span className=''>About</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-2'>
                                <Link to="/contact">
                                    <span className=''>Contact</span>
                                </Link>
                            </div>
                        </div>}
                    <div onClick={() => setShow(prev => {
                        if (prev === "0") {
                            return "1"
                        } else {
                            return "0"
                        }
                    })}
                        className='d-flex align-items-center mt-4 mb-2'>

                        <span className='fs-6'>Other Pages</span>

                        <span className="material-symbols-outlined ms-auto cursor-p">
                            expand_more
                        </span>
                    </div>
                    {show === "1" &&
                        <div className='bg-light p-2 rounded'>
                            <div className='d-flex align-items-center fs-6 my-2'>
                                <Link to="/blog">
                                    <span className=''>Blog</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-3'>
                                <Link to="/about">
                                    <span className=''>About</span>
                                </Link>
                            </div>
                            <div className='d-flex align-items-center fs-6 my-2'>
                                <Link to="/contact">
                                    <span className=''>Contact</span>
                                </Link>
                            </div>
                        </div>}

                </div>
                <div className="dropdown d-block-1000">
                    <button className="btn dropdown-toggle"
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowLan("show")
                    }}
                     type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Language
                    </button>
                    <ul className={`dropdown-menu ${showLan}`}
                     onClick={() => setShowLan("")}
                    >
                        <li><a className="dropdown-item" href="#">ENGLISH</a></li>
                        <li><a className="dropdown-item" href="#">SPANISH</a></li>
                        <li><a className="dropdown-item" href="#">TURKISH</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
