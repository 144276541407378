import React, { useContext, useEffect, useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import { AuthContext } from '../../Context'

export default function Peyment() {

    const [radioBtn, setRadioBtn] = useState("1")

    let { userInfos, isLogin, setIsLoding, isLoading } = useContext(AuthContext)

    return (
        <>
            <Header />
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-lg-8 col-xs-12'>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Confirm and payment</h2>
                            <hr className='w-25 my-4' />
                            <h4 className='h4 my-4'>Your trip</h4>
                            <div className='border rounded-25p mx-1'>
                                <div className='row'>
                                    <div className='col-sm-6 col-12 border-end'>
                                        <div className='d-flex p-2 align-items-center'>
                                            <div className=' position-relative'>
                                                <p className='ps-2'>check-in</p>
                                                <p className='parag cursor-p p-2'>12/19</p>
                                            </div>
                                            <span className="material-symbols-outlined parag fs-2 ms-5">
                                                calendar_today
                                            </span>

                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-12'>
                                        <div className='d-flex py-2 align-items-center'>
                                            <div className=' position-relative'>
                                                <p className='ps-2'>check-out</p>
                                                <p className='parag cursor-p p-2'>17/12</p>
                                            </div>
                                            <span className="material-symbols-outlined parag fs-2 ms-5">
                                                calendar_today
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className='border-top'>
                                    <div className='d-flex px-3 py-1 align-items-center'>
                                        <div>
                                            <div className='form-control border-none p-bold' >{4} Guests</div>
                                            <p className='text-secondary cursor-p p-2'>guest</p>
                                        </div>
                                        <span className="material-symbols-outlined parag fs-2 ms-5">
                                            person
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <h4 className='h4 my-4'>Pay with</h4>
                            <hr className='my-4 w-25' />
                            <div>
                                <div className='row text-center text-secondary my-4 border rounded-25p bg-light col-lg-6 col-md-12'>
                                    <div onClick={() => setRadioBtn("1")}
                                        className={radioBtn === "1" ? 'col-6 p-3 border bg-dark text-white rounded-25p' : "col-6 p-3"}
                                    >Paypal</div>
                                    <div onClick={() => setRadioBtn("2")}
                                        className={radioBtn === "2" ? 'col-6 p-3 border bg-dark text-white rounded-25p' : "col-6 p-3"}
                                    >Credite card</div>
                                </div>
                            </div>
                            {radioBtn === "1" ? (
                                <div>
                                    <div className="mb-4">
                                        <label htmlFor="exampleFormControlInput16" className="form-label">Card number</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput16" placeholder="111 112 222 999" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="exampleFormControlInput15" className="form-label">Card holder</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput15" placeholder="JOHN DOE" />
                                    </div>
                                    <div className='d-flex'>
                                        <div className="mb-4 pe-3 w-50">
                                            <label htmlFor="exampleFormControlInput14" className="form-label">Expiration date</label>
                                            <input type="text" className="form-control" id="exampleFormControlInput14" placeholder="dd/mm/yyyy" />
                                        </div>
                                        <div className="mb-4 ps-3 w-50">
                                            <label htmlFor="exampleFormControlInput13" className="form-label">CVC</label>
                                            <input type="text" className="form-control" id="exampleFormControlInput13" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Messager for author</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                    <p className='text-secondary mb-3'>Write a few sentences about yourself.</p>
                                    <br></br>
                                    <span className=' px-3 py-2 border text-white bg-primary cursor-p rounded-25p my-3'>Confirm and pay</span>
                                </div>) : (
                                <div>
                                    <div className="mb-4">
                                        <label htmlFor="exampleFormControlInput12" className="form-label">Email</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput12" placeholder="example@gmail.com" />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="exampleFormControlInput11" className="form-label">Password</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput11" placeholder="***" />
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Messager for author</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                    <p className='text-secondary mb-3'>Write a few sentences about yourself.</p>
                                    <br></br>
                                    <span className=' px-3 py-2 border text-white bg-primary cursor-p rounded-25p my-3'>Confirm and pay</span>
                                </div>
                            )}
                        </section>
                    </div>

                    <div className='col-lg-4 col-xs-12 '>
                        <div className='basket-box-position-sticky'>
                            <section className='p-4 my-5 border rounded-25p '>
                                <div className='py-2 d-flex align-items-center flex-wrap mb-3'>
                                    <img src={`/img/q3.jpg`} className='rounded-25p' style={{ width: 170, height: 170 }} />
                                    <div className="ps-2">
                                        <div className='parag'>{"Best House"}</div>
                                        <p className='my-2'>The Lounge & Bar</p>
                                        <p className='parag'>{4} beds ·  1 baths</p>
                                        <hr className='w-25' />
                                        <div className='d-flex align-items-center'>
                                            <span className="material-symbols-outlined text-warning">
                                                star
                                            </span>
                                            <div className='mx-1 p-bold'>4.4</div>
                                            <div className='small parag'>(153)</div>
                                        </div>
                                    </div>
                                </div>
                                <h3 className='h4'>Price detail</h3>
                                <div className='d-flex align-items-center p-2 mt-3'>
                                    <span className=' parag'>${200} x {2} night</span>
                                    <span className=' ms-auto parag '>${400}</span>
                                </div>
                                <div className='d-flex align-items-center p-2'>
                                    <span className=' parag'>Service charge</span>
                                    <span className=' ms-auto parag '>$30</span>
                                </div>
                                <hr className='' />
                                <div className='d-flex align-items-center p-2'>
                                    <span className='p-bold '>Total</span>
                                    <span className=' ms-auto p-bold '>${400 + 30}</span>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
