import React, { useState } from 'react'
import Cars from './CarsSelect'
import Experiences from './Experiences'
import Flights from './Flights'
import Stays from './Stays'
import "./TravelSearch.css"

export default function TravelSearch() {

    const [show, setShow] = useState("stays")

    return (
        <div className='container '>
            <div className=' py-3 container'>
                <div className='col-lg-6 col-12'>
                    <ul className='d-flex align-items-center row '>
                        <li className={`px-3 h6 col-lg-3 col-6 col-sm-6 cursor-p ${show === "stays" && "list-active"}`}
                            onClick={() => setShow("stays")}>Stays</li>
                        <li className={`px-3 h6 col-lg-3 col-6 col-sm-6 cursor-p ${show === "experiences" && "list-active"}`}
                            onClick={() => setShow("experiences")}>Experiences</li>
                        <li className={`px-3 h6 col-lg-3 col-6 col-sm-6 cursor-p ${show === "cars" && "list-active"}`}
                            onClick={() => setShow("cars")}>Cars</li>
                        <li className={`px-3 h6 col-lg-3 col-6 col-sm-6 cursor-p ${show === "flights" && "list-active"}`}
                            onClick={() => setShow("flights")}>Flights</li>
                    </ul>
                </div>
            </div>
            {show === "stays" && <Stays />}
            {show === "experiences" && <Experiences />}
            {show === "cars" && <Cars />}
            {show === "flights" && <Flights />}
        </div>
    )
}
