
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import CityCard from "../Cards/CityCard";

export default function Slider1() {
  return (
    <div className="container">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          550: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          968: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1224: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
          1424: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><CityCard img="/img/s1.jpg" title="Wooden House" detail="Some quick example text"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s2.jpg" title="White House" detail=" quick example text"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s3.jpg" title="City House" detail="example text Some quick"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s4.jpg" title="Near the see" detail="Some quick example text"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s5.jpg" title="Fantastic House" detail="quick example text"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s3.jpg" title="Lovly House" detail="example text Some quick"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s6.jpg" title="Cambrige House" detail="Some quick example text"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s4.jpg" title="Vodafone Real" detail="example text Some quick"/></SwiperSlide>
        <SwiperSlide><CityCard img="/img/s7.jpg" title="Wooden House" detail="Some quick example text"/></SwiperSlide>
      </Swiper>
    </div>
  );
}
