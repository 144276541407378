import React, { createContext, useEffect, useState } from 'react'
import Swal from "sweetalert2"
import {useNavigate} from "react-router-dom"


export const AuthContext = createContext()

export default function ContextProvider({ children }) {

    const [isLogin, setIsLogin] = useState(false)
    const [isLoading, setIsLoding] = useState(false)
    const [userInfos, setUserInfos] = useState({})

  const navigate = useNavigate()

    const logOut = () => {
        setIsLogin(false)
        setUserInfos({})
        window.localStorage.removeItem("token")
    }

    const register = async (user) => {
        setIsLoding(true)
        try {
            const res = await fetch("https://airbnb-api-six.vercel.app/api/users/create"
                , {
                    method: "POST",
                    body: JSON.stringify(user)

                }
            )
            if (!res) return false
            const data = await res.json()
            console.log(data);
            if (data?.resulte) {
                setIsLogin(true)
                setUserInfos(data.user)
                window.localStorage.setItem("token" , data.token)
                Swal.fire({
                    icon: "success",
                    text: "signup successfully"
                })
                navigate("/")
                
            } else {
                Swal.fire({
                    icon: "error",
                    text: "please try again"
                })
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                text: "please try again"
            })
        }

        setIsLoding(false)
    }

    const login = async (user) => {
        setIsLoding(true)

        try {
            const res = await fetch("https://airbnb-api-six.vercel.app/api/users/login"
                , {
                    method: "POST",
                    body: JSON.stringify(user)

                }
            )
            if (!res) return false
            const data = await res.json()
            console.log(data);
            if (data?.resulte) {
                setIsLogin(true)
                setUserInfos(data.user)
                Swal.fire({
                    icon: "success",
                    text: "login successfully"
                })
                window.localStorage.setItem("token" , data.token)
                navigate("/")
            } else {
                Swal.fire({
                    icon: "error",
                    text: "please try again",
                    title: data?.message
                })
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                text: "please try again"
            })
        }

        setIsLoding(false)
    }


    const getUser = async () => {
        setIsLoding(true)

        if (window.localStorage.getItem("token")) {

            try {
                const token = window.localStorage.getItem("token")

                const res = await fetch("https://airbnb-api-six.vercel.app/api/users/getInfo", {
                    method: "POST",
                    body: JSON.stringify({ token })
                })

                const data = await res.json()

                if (data?.resulte) {
                    setIsLogin(true)
                    setUserInfos(data.user)
                } else {
                    console.log("token is wrong")
                    isLogin(false)
                    setUserInfos({})
                }
            }
            catch (error) {
                console.log(error);
            }
        } else {
            setIsLoding(false)
            setUserInfos({})
        }

        setIsLoding(false)
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <AuthContext.Provider value={{
            isLogin,
            userInfos,
            logOut,
            isLoading,
            register ,
            login
        }}>
            {children}
        </AuthContext.Provider>
    )
}
