import React from 'react'
import Header from "../../Components/Header/Header"
import Footer from "../../Components/Footer/Footer"
import SubsBox from '../../Components/SubsBox/SubsBox'

export default function Subscription() {
  return (
    <>
        <Header />
            <div className='container text-center'>
                <h1 className='h1 mt-4'>Subscription</h1>
                <p className='parag m-3'>Pricing to fit the needs of any companie size.</p>
                <div className='row my-4'>
                    <SubsBox cost={5}/>
                    <SubsBox cost={15}/>
                    <SubsBox cost={25}/>
                </div>
            </div>
        <Footer />
    </>
  )
}
