import React, { useContext, useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Progress from '../../Components/Progress/Progress'
import "./AddListing.css"
import Counter from "../../Components/Counter/Counter"
import Slider2 from '../../Components/Slider2/Slider2'
import { AuthContext } from '../../Context'
import Swal from 'sweetalert2'

export default function AddListing() {

    const [page, setPage] = useState(0)
    const [stay, setStay] = useState({})
    const [price, setPrice] = useState(0)
    const [title, setTitle] = useState("")
    const [city, setCity] = useState("")

    const { userInfos } = useContext(AuthContext)

    const stayHandler = (e) => {
        setStay(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }


    const createStay = async () => {
        if(title.length < 3){
            Swal.fire({
                icon: "error",
                text : "title is NOT valid"
            })
            return false
        }
        try {
            const res = await fetch("https://airbnb-api-six.vercel.app/api/posts/create", {
                method: "POST",
                body: JSON.stringify({
                    title,
                    price,
                    img: stay.img?.slice(12),
                    address: stay.country,
                    details: "",
                    category: stay.category,
                    userId: userInfos._id,
                })
            })
            const data = await res.json()
            console.log(data);
            if (data?.resulte) {
                setPage(7)
                Swal.fire({
                    icon: "success",
                    text: "Your Information send successfully"
                })
            } else {
                Swal.fire({
                    icon: "error",
                    text : "informatin is NOT valid"
                })
            }

        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                text: "error plaese try again"
            })
        }
    }
 
    return (
        <>
            <Header />
            <div className='mx-auto my-4 col-xl-6 col-lg-8 col-md-10  col-sm-12'>
                <div className='d-flex align-items-center ps-3 py-3'>
                    <div>
                        <span className='h4'>0{page + 1}</span>
                        <span>/8</span>
                    </div>
                    {title && stay.property && stay.rental && stay.country && stay.img && (
                        <div className='alert alert-success ms-5'> Form is valid </div>
                    )}
                    <div className='ms-auto'>
                        <Progress number={page} />
                    </div>
                </div>
                <div className='border p-5 rounded-25p'>
                    {/* page 0 */}
                    <div className={page == 0 ? `d-block` : "d-none"}>
                        <h3 className='h3'>Choosing listing categories</h3>
                        <label className="form-label mt-4">Choose a Main type *</label>
                        <select onChange={stayHandler} name="category"
                            className='form-select'>
                            <option value="STATE">Stay</option>
                            <option value="CAR">Car</option>

                        </select>
                        <hr className='w-25 my-3' />
                        <div className='w-100'>
                            {stay.category !== "car" ? (
                                <div className="mb-5">
                                    <label className="form-label">Choose a property type *</label>
                                    <select onChange={stayHandler} name="property"
                                        className='form-select'>
                                        <option value="Hotel">Hotel</option>
                                        <option value="Villa">Villa</option>
                                        <option value="Houseboat">Houseboat</option>
                                    </select>
                                    <p className='small text-secondary mt-2 lh-sm'>Hotel: Professional hospitality businesses that usually
                                        have a unique style or theme defining their brand and decor
                                    </p>
                                </div>
                            ) : (
                                <div className="mb-5">
                                    <label className="form-label">Choose a property type *</label>
                                    <select onChange={stayHandler} name="property"
                                        className='form-select'>
                                        <option value="Hotel">Small</option>
                                        <option value="Villa">Medium</option>
                                        <option value="Houseboat">Van</option>
                                    </select>
                                    <p className='small text-secondary mt-2 lh-sm'>Van: Professional hospitality businesses that usually
                                        have a unique style or theme defining their brand and decor
                                    </p>
                                </div>
                            )}
                            <div className="mb-5">
                                <label className="form-label">{stay.category === "CAR" ? "Car Title" : "Place name"} *</label>

                                <input
                                    className="form-control"
                                    type="text"
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                />

                                <p className='small text-secondary mt-2 lh-sm'>A catchy name usually includes: House name + Room name + Featured property
                                    + Tourist destination
                                </p>
                            </div>
                            {stay.category === "CAR" ? (
                                <div className="mb-4">
                                    <label className="form-label">Drop off</label>
                                    <select
                                        className='form-select'>
                                        <option value="same">Same drop off</option>
                                        <option value="different">Different drop off</option>
                                    </select>
                                    <p className='small text-secondary mt-2 lh-sm'>Different drop off: Guests have the whole place to themselves—there's
                                        a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included.
                                    </p>
                                </div>
                            ) : (
                                <div className="mb-4">
                                    <label className="form-label">Rental form *</label>
                                    <select onChange={stayHandler} value={stay?.rental} name="rental"
                                        className='form-select'>
                                        <option value="Entire place">Entire place</option>
                                        <option value="Private room">Private room</option>
                                        <option value="Share room">Share room</option>
                                    </select>
                                    <p className='small text-secondary mt-2 lh-sm'>Entire place: Guests have the whole place to themselves—there's
                                        a private entrance and no shared spaces. A bedroom, bathroom, and kitchen are usually included.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* page 1 */}
                    <div className={page == 1 ? `d-block` : "d-none"}>
                        <h3 className='h3'>Your place location</h3>
                        <hr className='w-25 my-4' />
                        <div className='w-100'>

                            <div className="mb-5">
                                <label className="form-label">Country/Region *</label>
                                <select onChange={stayHandler} name="country"
                                    className='form-select'>
                                    <option value="France">France</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Jappan">Jappan</option>
                                </select>
                            </div>
                            <div className="mb-5">
                                <label className="form-label">City *</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="city"
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                />

                            </div>
                            <div className='d-flex flex-wrap'>
                                <div className="mb-5 me-3">
                                    <label className="form-label">Street *</label>
                                    <input onChange={stayHandler} value={stay?.street} name="street"
                                        type="text" className="form-control" placeholder="Street" />
                                </div>
                                <div className="mb-5 me-3">
                                    <label className="form-label">State</label>
                                    <input type="text" className="form-control" placeholder="State" />
                                </div>
                                <div className="mb-5">
                                    <label className="form-label">Postal code</label>
                                    <input type="text" className="form-control" placeholder="Postal code" />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Detailed address</label>
                                <p className='small text-secondary mt-2 lh-sm'>
                                    1110 Pennsylvania Avenue NW, Washington, DC 20230
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* page 2 */}
                    <div className={page == 2 ? `d-block` : "d-none"}>
                        {stay.category !== "CAR" ? (
                            <>
                                <h3 className='h3'>Size of your location</h3>
                                <hr className='w-25 my-4' />
                                <div className='w-100'>

                                    <div className="mb-5">
                                        <label className="form-label">Aceage(m2) *</label>
                                        <select onChange={stayHandler} value={stay?.aceage} name="aceage"
                                            className='form-select'>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                            <option value={300}>300</option>
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='fs-5'>Guests</div>
                                        <div className='ms-auto'>
                                            <Counter defaultCount={4} />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='fs-5'>bedroom</div>
                                        <div className='ms-auto'>
                                            <Counter defaultCount={2} />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='fs-5'>Beds</div>
                                        <div className='ms-auto'>
                                            <Counter defaultCount={3} />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='fs-5'>Bathroom</div>
                                        <div className='ms-auto'>
                                            <Counter defaultCount={1} />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='fs-5'>kitchen</div>
                                        <div className='ms-auto'>
                                            <Counter defaultCount={4} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <h3 className='h3'>Seats of your Car</h3>
                                <hr className='w-25 my-4' />
                                <div className='w-100'>

                                    <div className="mb-5">
                                        <label className="form-label">Seats *</label>
                                        <select onChange={stayHandler} name="seats"
                                            className='form-select'>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                            <option value={7}>7</option>
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='fs-5'>Passengers</div>
                                        <div className='ms-auto'>
                                            <Counter defaultCount={4} />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='fs-5'>Bags</div>
                                        <div className='ms-auto'>
                                            <Counter defaultCount={2} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                    {/* page 3 */}
                    <div className={page == 3 ? `d-block` : "d-none"}>
                        {stay.category === "CAR" ? (
                            <>
                                <h3 className='h3'>Car specifications</h3>
                                <p className='small text-secondary mt-2 lh-sm'>
                                    Many customers have searched for accommodation based on amenities criteria
                                </p>

                                <hr className='w-25 my-4' />
                                <div className='w-100'>
                                    <h5>Car specifications</h5>
                                    <div className="mt-5 row">
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                            <div className='mb-4'>
                                                <input type="checkbox" className='form-check-input me-2' />
                                                With air conditioning
                                            </div>
                                            <div className='mb-4'>
                                                <input type="checkbox" className='form-check-input me-2' />
                                                Automatic transmission
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                            <div className='mb-4'>
                                                <input type="checkbox" className='form-check-input me-2' />
                                                Manual transmission
                                            </div>
                                            <div className='mb-4'>
                                                <input type="checkbox" className='form-check-input me-2' />
                                                2 doors
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                            <div className='mb-4'>
                                                <input type="checkbox" className='form-check-input me-2' />
                                                4+ doors
                                            </div>
                                            <div className='mb-4'>
                                                <input type="checkbox" className='form-check-input me-2' />
                                                Alamo
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <h3 className='h3'>Amenities</h3>
                                <p className='small text-secondary mt-2 lh-sm'>
                                    Many customers have searched for accommodation based on amenities criteria
                                </p>

                                <hr className='w-25 my-4' />
                                <div className='w-100'>
                                    <h5>General amenities</h5>
                                    <div className="mb-5 row">
                                        <div className='col-lg-4 col-sm-6'>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Wifi
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" defaultChecked />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Air conditioning
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Dryer
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Detergent
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" defaultChecked />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Desk
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-sm-6'>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Internet
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Fan
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Heater
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" defaultChecked />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Clothes dryer
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Fridge
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-sm-6'>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" defaultChecked />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    TV
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Private entrance
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Wifi
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Washing machine
                                                </label>
                                            </div>
                                            <div className=" my-3 align-items-center d-flex">
                                                <input className="form-check-input" type="checkbox" value="" defaultChecked />
                                                <label className="form-check-label ms-3" htmlFor="flexCheckDefault">
                                                    Extra cushion
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* page 4 */}
                    <div className={page == 4 ? `d-block` : "d-none"}>
                        <h3 className='h3'>Pictures of the place</h3>
                        <p className='small text-secondary mt-2 lh-sm'>
                            A few beautiful photos will help customers have more sympathy for your property.
                        </p>
                        <hr className='w-25 my-4' />
                        <div className='w-100'>

                            <div className="mb-5">
                                <label className="mb-3 fw-bold">Cover image</label>
                                <div className='border d-flex align-items-center justify-content-center w-100 p-4'>
                                    <div className='text-center'>
                                        <span className="material-symbols-outlined fs-1 parag">
                                            image
                                        </span>
                                        <label className='label-file-input'> Uplode Your place image *
                                            <input onChange={stayHandler} name="img" value={stay?.img}
                                                type="file" size="60" />
                                        </label>
                                        <p className='small text-secondary mt-2 lh-sm'>
                                            PNG, JPG, GIF up to 10MB
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <label className="mb-3 fw-bold">Pictures of the place</label>
                                <div className='border d-flex align-items-center justify-content-center w-100 p-4'>
                                    <div className='text-center'>
                                        <span className="material-symbols-outlined fs-1 parag">
                                            image
                                        </span>
                                        <label className='label-file-input'> Uplode Your place
                                            <input type="file" size="60" />
                                        </label>
                                        <p className='small text-secondary mt-2 lh-sm'>
                                            PNG, JPG, GIF up to 10MB
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* page 5 */}
                    <div className={page == 5 ? `d-block` : "d-none"}>
                        <h3 className='h3'>Price your space</h3>
                        <p className='small text-secondary mt-2 lh-sm'>
                            The host's revenue is directly dependent on the setting of rates and regulations
                            on the number of guests, the number of nights, and the cancellation policy.
                        </p>
                        <hr className='w-25 my-4' />
                        <div className='w-100'>

                            <div className="mb-5">
                                <label className="form-label">Currency</label>
                                <select className='form-select'>
                                    <option>USD</option>
                                    <option>EURRO</option>
                                    <option>VND</option>
                                </select>
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Base price (Monday -Thuday) *</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    id="price"
                                    value={price}
                                    onChange={e => setPrice(e.target.valueAsNumber)}
                                />
                                {/* <input type="text" className="form-control" placeholder="$ 0.00" /> */}
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Base price (Friday-Sunday)</label>
                                <input type="text" className="form-control" placeholder="$ 0.00" />
                            </div>
                            <div className="mb-5">
                                <label className="form-label">Long term price (Monthly discount)</label>
                                <input onChange={stayHandler} name="discount" value={stay?.discount}
                                    type="text" className="form-control" placeholder="% 0.00" />
                            </div>

                        </div>
                    </div>
                    {/* page 6 */}
                    <div className={page == 6 ? `d-block` : "d-none"}>
                        <h3 className='h3'>Congratulations </h3>
                        <p className='small text-secondary mt-2 lh-sm'>
                            Excellent, congratulations on completing the listing,
                            it is waiting to be reviewed for publication
                        </p>
                        <hr className='w-25 my-4' />
                        <div className='w-100'>
                            <div className='col-xl-6 col-lg-8 col-md-9 p-3 col-sm-10 col-xs-12 ' style={{ overflow: "hidden" }}>
                                <div className="card border-none shadow place-card-main-div">
                                    <Slider2 img={stay.img?.slice(12)} />
                                    <span className="material-symbols-outlined place-card-fav-icon">
                                        favorite
                                    </span>
                                    {stay.discount && <button className={`btn btn-danger w-25  btn-sm  rounded-50p place-card-off-btn`}>
                                        -{stay.discount}% today</button>}
                                    <div className="card-body">
                                        <p className='parag'>Entire cabin . 3 beds</p>
                                        <h5 className="card-title my-2 h5">{title}</h5>
                                        <p className="card-text my-1 parag align0items-center d-flex">
                                            <span className="material-symbols-outlined me-1">
                                                location_on
                                            </span>
                                            {stay.street}{" "} {city}</p>

                                    </div>
                                    <div className='p-3 d-flex align-items-center'>
                                        <div className="p-bold">${title}</div>
                                        <div className='small parag me-auto'>/night</div>
                                        <div><span className="material-symbols-outlined text-warning">
                                            star
                                        </span></div>
                                        <div className='mx-1 p-bold'>5</div>
                                        <div className='small parag'>(1)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* page 7 */}
                    <div className={page == 7 ? `d-block` : "d-none"}>
                        <div className='alert alert-success'>Published successfully</div>
                    </div>



                </div>
                <div className='my-3 d-flex'>
                    {page === 0 || page === 7 ? (<><div className='ms-auto'></div></>) : (
                        <button onClick={() => setPage(prev => prev - 1)}
                            className="btn btn-outline-secondary btn-lg rounded-25p ms-auto me-2 py-2 px-3"
                        >Go back</button>)}
                    {page < 6 ? (
                        <button className="btn btn-primary btn-lg rounded-25p py-2 px-3 me-1"
                            onClick={() => setPage(prev => prev + 1)}>{page === 6 ? "Publish listing" : "Continue"}</button>) : page == 6 ? (
                                <>
                                    <button className="btn btn-primary btn-lg rounded-25p py-2 px-3 me-1"
                                        onClick={() => createStay()}>Publish listing</button>

                                </>) : <></>}
                </div>
            </div>
            <Footer />
        </>
    )
}
