import React from 'react'

export default function CityCard({img , title , detail}) {
    return (
        <div className="" >
            <img src={img} className="card-img-top mb-3 rounded-50p" alt="..." />
            <div className="card-body">
                <p className="card-text h5">{title}</p>
                <p className="card-text">{detail}</p>
            </div>
        </div>
    )
}
