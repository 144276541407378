import Header from '../../Components/Header/Header'
import MainDisplay from '../../Components/MainDisplay/MainDisplay'
import Newsletter from '../../Components/Newsletter/Newsletter'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import Footer from '../../Components/Footer/Footer'
import CarsBoxes from '../../Components/CarsBoxes/CarsBoxes'
import CityCard from '../../Components/Cards/CityCard'

export default function Cars() {

    return (
        <div>
            <Header />
            <MainDisplay img="car.png">
                <div className='col-md-6 col-sm-12'>
                    <h2 className='h0 mt-5'>Cars, Jappan</h2>
                    <div className='my-5 lead d-flex align-items-center'>
                        <div className='lead d-flex align-items-center'>
                            <span className="material-symbols-outlined">
                                map
                            </span>
                            <span className='parag ms-2'>Jappan</span>
                        </div>
                        <div className='lead ms-4 d-flex align-items-center'>
                            <span className="material-symbols-outlined">
                                directions_car
                            </span>
                            <span className='parag ms-2'>1843 Cars</span>
                        </div>
                    </div>
                </div>
            </MainDisplay>
            <div className='bg-light rounded py-3'>
                <SectionHeader title="Cars in Tokyo" subTitle="233 cars·Aug 12 - 18" />
                <CarsBoxes />
            </div>
            <div className='text-center bg-personal py-3'>
                <SectionHeader title="Explore top destination " subTitle="Explore thousands of destinations around the world" />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                            <CityCard img="/img/s4.jpg" />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                            <CityCard img="/img/s1.jpg" />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                            <CityCard img="/img/s3.jpg" />
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                            <CityCard img="/img/s4.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
            <Footer />
        </div>
    )
}
