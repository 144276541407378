import React from 'react'
import Header from "../../Components/Header/Header"
import Footer from "../../Components/Footer/Footer"
import SocialIcon from '../../Components/SocialIcon/SocialIcon'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import Newsletter from '../../Components/Newsletter/Newsletter'
import Slider4 from '../../Components/Slider4/Slider4'


export default function Contact() {
    return (
        <>
            <Header />
            <div className='container py-4'>
                <h2 className='h1 text-center m-3'>Contact</h2>
                <div className='row'>
                    <div className='col-md-6 col-xs-12 p-3'>
                        <div className='my-3'>
                            <div className='d-flex align-items-center'>
                                <span className="material-symbols-outlined me-2 text-primary">
                                    import_contacts
                                </span>
                                <span className='p-bold'>ADDRESS</span>
                            </div>
                            <p className='parag w-75 fs-5 mb-4 mt-2'>Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter</p>
                        </div>
                        <div className='my-3'>
                            <div className='d-flex align-items-center'>
                                <span className="material-symbols-outlined me-2 text-primary">
                                    email
                                </span>
                                <span className='p-bold'>EMAIL</span>
                            </div>
                            <p className='parag w-75 fs-5 mb-4 mt-2'>Bahaminwp@gmail.com</p>
                        </div>
                        <div className='my-3'>
                            <div className='d-flex align-items-center'>
                                <span className="material-symbols-outlined me-2 text-primary">
                                    PHONE
                                </span>
                                <span className='p-bold'>PHONE</span>
                            </div>
                            <p className='parag w-75 fs-5 mb-4 mt-2'>0090-535-484-3540</p>
                        </div>
                        <div className='my-3'>
                            <div className='d-flex align-items-center'>
                                <span className="material-symbols-outlined me-2 text-primary">
                                    public
                                </span>
                                <span className='p-bold'>SOCIALS</span>
                            </div>
                            <div className='d-flex mt-2 text-info'>
                                <SocialIcon icon="facebook" />
                                <SocialIcon icon="twitter" />
                                <SocialIcon icon="youtube" />
                                <SocialIcon icon="instagram" />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-xs-12 p-3'>
                        <div>
                            <div className="mb-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Full name</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Example DOE" />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="exampel@example.com" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
                            </div>
                            <br></br>
                            <span className=' px-3 py-2 border text-white bg-primary cursor-p rounded-25p mt-3'>send message</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center bg-light py-3'>
                <SectionHeader title="Good news from far away" subTitle="Let's see what people think of Chisfis" />
                <div className='container'>
                    <Slider4 />
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    )
}
