import React, { useState } from 'react'

export default function Counter({defaultCount , className}) {
  const [count , setCount] = useState(defaultCount)
  return (
    <div className={className}>
        <div className='rounded d-flex justify-content-center align-items-center p-3 m-2 text-dark'>
            <button onClick={() => setCount(prev => prev - 1)}
            className='btn btn-light border rounded-50p d-flex align-items-center '>-</button>
            <span className='mx-3'>{count}</span>
            <button onClick={() => setCount(prev => prev + 1)}
            className='btn btn-light border rounded-50p d-flex align-items-center '>+</button>
        </div>
    </div>
  )
}
