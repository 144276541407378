import React, { useContext, useEffect, useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import Gallery from '../../Components/Gallery/Gallery'
import Header from '../../Components/Header/Header'
import GoogleMap from '../../Components/Mapp/Mapp'
import Calendar from 'react-calendar';
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import SliderExplore from '../../Components/SliderExplore/SliderExplore'
import Newsletter from '../../Components/Newsletter/Newsletter'
import { useNavigate, useParams } from 'react-router-dom'
import Reviews from '../../Components/Reviews/Reviews'
import { Toast } from '../../Components/Toast/Toast'
import { AuthContext } from '../../Context'


export default function CarSingle() {

    const [info, setInfo] = useState({})
    const [basket, setBasket] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [value, onChange] = useState(new Date());
    const [secondValue, secondOnChange] = useState(new Date());

    const { infoId } = useParams()

    const getCarInfo = async () => {

        const res = await fetch(`https://airbnb-api-six.vercel.app/api/posts/getInfo`,{
            method : "POST",
            body:JSON.stringify({id : infoId})
        })
        const data = await res.json()
        setInfo(data.post)
    }


    useEffect(() => {
        getCarInfo()
    }, [])

    const changeHandler = (e) => {
        setBasket(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const navigate = useNavigate()

    let { userInfos, isLogin } = useContext(AuthContext)

    const notify = () => {
        setShowModal(true)

        setTimeout(() => {
            setShowModal(false)
        }, 2000);
    }

    let checkInValue = new Date(value)
    let checkOutValue = new Date(secondValue)

    let checkInDay = new Date(value).getDate()
    let checkInMonth = new Date(value).getMonth()
    let checkInYear = new Date(value).getFullYear()
    let checkOutDay = new Date(secondValue).getDate()
    let checkOutMonth = new Date(secondValue).getMonth()
    let checkOutYear = new Date(secondValue).getFullYear()
 


    const reserveHandler = async () => {
        // if (isLogin) {
        //     let { data, error } = await Supabase.from("reserves")
        //         .insert({
        //             checkIn: checkInValue,
        //             checkOut: checkOutValue,
        //             category: false,
        //             dayCount: (checkOutValue.getTime() - checkInValue.getTime()) / 86400000,
        //             total: (info.price * (checkOutValue.getTime() - checkInValue.getTime()) / 86400000),
        //             token: userInfos.token,
        //             rentId: infoId,
        //             info
        //         })

        //     if (error === null) {
        //         navigate("/peyment")
        //     }
        // } else {
        //     notify()
        //     navigate("/login")
        // }

    }


    return (
        <div>
            <Header />
            <Gallery img={info.img} images={["mo1.jpg", "mo2.jpg", "mo3.jpg", "mo4.jpg"]} />
            <Toast show={showModal} title="Please sign up" />
            <div className='container my-3'>
                <div className='row'>
                    <div className='col-lg-8 col-sm-12'>
                        <section className='p-4 my-5 border rounded-25p'>
                            <div className='d-flex align-items-center'>
                                <span className='text-primary bg-personal py-2 px-3 rounded-25p'>BMW car</span>
                                <span className='ms-auto d-flex align-items-center'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        ios_share
                                    </span>
                                    <span className='parag fs-6'>Share</span>
                                </span>
                                <span className='d-flex align-items-center ms-4'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        favorite
                                    </span>
                                    <span className='parag fs-6'>Share</span>
                                </span>
                            </div>
                            <h2 className='h1 my-3'>{info.title} 3 Series Sedan</h2>
                            <div className='py-3 d-flex align-items-center'>
                                <div>
                                    <span className="material-symbols-outlined text-warning">
                                        star
                                    </span>
                                </div>
                                <div className='mx-1 p-bold'>{info.rate}</div>
                                <div className='small parag me-5'>(153)</div>
                                <p className="card-text my-1 parag align0items-center d-flex">
                                    <span className="material-symbols-outlined me-1">
                                        location_on
                                    </span>
                                    {info.location}
                                </p>
                            </div>
                            <div className=" d-flex  align-items-center my-3" >
                                <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                                <p className=" parag me-1">Car owner</p>
                                <h5 className="p-bold"> Kevin Francis</h5>
                            </div>
                            <div className='align-items-center mt-3 py-3 border-top row'>

                                <span className='d-flex align-items-center col-lg-3 col-6 '>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        person
                                    </span>
                                    <span className='parag fs-6 mx-1 foont-14-450'>{info.seats} seats</span>
                                </span>
                                <span className='d-flex align-items-center  col-lg-3 col-6'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        settings
                                    </span>
                                    <span className='parag fs-6 mx-1 foont-14-450'>Auto gearbox</span>
                                </span>
                                <span className='d-flex align-items-center  col-lg-3 col-6'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        luggage
                                    </span>
                                    <span className='parag fs-6 mx-1 foont-14-450'>2 bags</span>
                                </span>
                            </div>

                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Vehicle parameters & utilities</h2>
                            <p className='parag my-4'>Questions are at the heart of making things great.</p>
                            <hr className='w-25 my-4' />

                            <div className='d-flex align-items-center row mt-3 py-3'>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        local_gas_station
                                    </span>
                                    <span className='parag fs-6 mx-1'>59 MPG Combined, 58 City/60 Hwy</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        speed
                                    </span>
                                    <span className='parag fs-6 mx-1'>139-hp gas/electric combined</span>
                                </span>

                            </div>
                            <div className='d-flex align-items-center row mt-2 py-2'>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        account_tree
                                    </span>
                                    <span className='parag fs-6 mx-1'>LED Daytime Running Lights (DRL)</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        fluorescent
                                    </span>
                                    <span className='parag fs-6 mx-1'>Forward Collision-Avoidance Assist with Pedestrian Detection (FCA-Ped)</span>
                                </span>

                            </div>
                            <div className='d-flex align-items-center row mt-2 py-2'>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        commute
                                    </span>
                                    <span className='parag fs-6 mx-1'>Proximity Key with push button start</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        ads_click
                                    </span>
                                    <span className='parag fs-6 mx-1'>8-inch color touchscreen display audio</span>
                                </span>
                            </div>
                            <div className='d-flex align-items-center row mt-2 py-2'>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        robot
                                    </span>
                                    <span className='parag fs-6 mx-1'>Smart Cruise Control with Stop & Go</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-6 col-sm-6 col-xs-12 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4">
                                        rv_hookup
                                    </span>
                                    <span className='parag fs-6 mx-1'>Blind-Spot Collision Warning (BCW)</span>
                                </span>
                            </div>

                            <hr className='w-25 my-5' />
                            <div></div>
                            <div className='my-4'>
                                <span className=' p-3 border parag cursor-p rounded-25p'>View more 20 amenities</span>
                            </div>
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Car descriptions</h2>
                            <hr className='w-25 my-4' />
                            <p className='parag'>Until the all-new TUCSON hits the dealer showrooms you can check it out in our Showroom Walkaround video. Watch the video
                                and join our product specialist as he gives you an up-close look of our latest SUV
                            </p>
                            <p className='parag my-4'>There is a private bathroom with bidet in all units, along with a hairdryer and free toiletries.
                            </p>
                            <p className='parag'>
                                Questions are at the heart of making things great. Watch our celebrity-filled TV ad and you’ll
                                see that when we say “everything,” we mean everything.
                            </p>
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Include</h2>
                            <p className='parag my-4'>Included in the price</p>
                            <hr className='w-25 my-4' />
                            <div className='row'>
                                <div className='d-flex p-3 col-lg-6 col-md-12 bg-light rounded'>
                                    <span className='parag'>Free cancellation up to 48 hours before pick-up</span>
                                    <span className='ms-auto parag'>
                                        <span className="material-symbols-outlined">
                                            preliminary
                                        </span>
                                    </span>
                                </div>
                                <div className='d-flex p-3 col-lg-6 col-md-12  rounded'>
                                    <span className='parag'>Theft Protection with $19,999 excess</span>
                                    <span className='ms-auto parag'>
                                        <span className="material-symbols-outlined">
                                            preliminary
                                        </span>
                                    </span>
                                </div>
                                <div className='d-flex p-3 col-lg-6 col-md-12 bg-light rounded'>
                                    <span className='parag'>Car interiors and exteriors cleaned with disinfectant before pick-up</span>
                                    <span className='ms-auto parag'>
                                        <span className="material-symbols-outlined">
                                            preliminary
                                        </span>
                                    </span>
                                </div>
                                <div className='d-flex p-3 col-lg-6 col-md-12  rounded'>
                                    <span className='parag'>Collision Damage Waiver with $214 deductible</span>
                                    <span className='ms-auto parag'>
                                        <span className="material-symbols-outlined">
                                            preliminary
                                        </span>
                                    </span>
                                </div>
                                <div className='d-flex p-3 col-lg-6 col-md-12 bg-light rounded'>
                                    <span className='parag'>Unlimited mileage</span>
                                    <span className='ms-auto parag'>
                                        <span className="material-symbols-outlined">
                                            preliminary
                                        </span>
                                    </span>
                                </div>
                                <div className='d-flex p-3 col-lg-6 col-md-12  rounded'>
                                    <span className='parag'>Masks are required at the pick-up location</span>
                                    <span className='ms-auto parag'>
                                        <span className="material-symbols-outlined">
                                            preliminary
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </section>
                        {/* calender */}
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Availability</h2>
                            <p className='parag my-4'>Prices may increase on weekends or holidays</p>
                            <hr className='w-25 my-4' />
                            <div className='row'>
                                <div className='col-lg-6 col-md-12 mb-4 border'>
                                    <Calendar onChange={onChange} value={value} className="me-5" />
                                </div>
                                <div className='col-lg-6 col-md-12 mb-4 border'>
                                    <Calendar onChange={secondOnChange} value={secondValue} />
                                </div>
                            </div>
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Car Owner</h2>

                            <div className=" d-flex  align-items-center my-3" >
                                <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                                <div>
                                    <h5 className="p-bold p-2"> Kevin Francis</h5>
                                    <div className='d-flex'>
                                        <span className="material-symbols-outlined text-warning">
                                            star
                                        </span>
                                        <div className='parag'>4.4(153) . 16 cars</div>
                                    </div>

                                </div>
                            </div>
                            <p className='parag pe-5 my-3'>Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides accommodation,
                                an outdoor swimming pool, a bar, a shared lounge, a garden and barbecue facilities...
                            </p>
                            <div className=''>
                                <span className='d-flex align-items-center my-3'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        calendar_today
                                    </span>
                                    <span className='parag fs-6 mx-1'>joined in march 2018</span>
                                </span>
                                <span className='d-flex align-items-center  my-3'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        comment
                                    </span>
                                    <span className='parag fs-6 mx-1'>respanse rate-90%</span>
                                </span>
                                <span className='d-flex align-items-center  my-3'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        schedule
                                    </span>
                                    <span className='parag fs-6 mx-1'>fast respanse - within a few hours</span>
                                </span>
                            </div>
                            <hr className='w-25 ' />
                            <div className='text-white'>.</div>
                            <div className='my-2'>
                                <span className=' p-3 border parag cursor-p rounded-25p'>See owner profile</span>
                            </div>
                        </section>
                        {/* comments */}
                        <Reviews />
                        {/* end comments */}
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Availability</h2>
                            <p className='parag my-4'>Prices may increase on weekends or holidays</p>
                            <hr className='w-25 my-4' />
                            <GoogleMap />
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Things to know</h2>
                            <hr className='w-25 my-4' />
                            <p className='h5 my-3'>Cancellation policy</p>

                            <p className='parag my-3 pe-3'>
                                Lock in this fantastic price today, cancel free of charge anytime.
                                Reserve now and pay at pick-up.
                            </p>
                            <hr className='w-25 my-4' />
                            <p className='h5 my-3'>Special Note</p>
                            <p className='parag my-3 pe-3'>
                                We asked ourselves, “How can we make the dash not only look better, but also give the driver
                                a better look outside?”
                                The unexpected answer is having no hood above the available 10.25-inch digital instrument cluster...
                            </p>
                        </section>
                    </div>
                    {/* sticky reserve form  */}
                    <div className='col-lg-4 col-xs-12 '>
                        <div className='basket-box-position-sticky'>
                            <section className='p-4 my-5 border rounded-25p '>
                                <div className='p-2 d-flex align-items-center mb-3'>
                                    <div className="p-bold h3">${info.price}</div>
                                    <div className='small parag me-auto'>/day</div>
                                    <div><span className="material-symbols-outlined text-warning">
                                        star
                                    </span></div>
                                    <div className='mx-1 p-bold'>{info.rate}</div>
                                    <div className='small parag'>(153)</div>
                                </div>
                                <div className='border rounded-25p mx-1'>
                                    <div className='row'>
                                        <div className='col-xxl-6 col-xl-12 col-lg-12 col-sm-6 col-xs-12 col-12'>
                                            <div className='d-flex p-2 align-items-center'>
                                                <span className="material-symbols-outlined parag fs-2">
                                                    calendar_today
                                                </span>
                                                <div className=' position-relative'>
                                                <p>{`${checkInDay}/${checkInMonth}/${checkInYear}`}</p>
                                                    {/* <input name="checkIn" onChange={changeHandler}
                                                        type="date" className='form-control border-none place check-in' /> */}
                                                    <p className='parag cursor-p p-2'>Check in</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-6 col-xl-12 col-lg-12 col-sm-6 col-xs-12 col-12'>
                                            <div className='d-flex p-2 align-items-center'>
                                                <span className="material-symbols-outlined parag fs-2">
                                                    calendar_today
                                                </span>
                                                <div className=' position-relative'>
                                                <p>{`${checkOutDay}/${checkOutMonth}/${checkOutYear}`}</p>
                                                    {/* <input name="checkOut" onChange={changeHandler}
                                                        type="date" className='form-control border-none place check-out' /> */}
                                                    <p className='parag cursor-p p-2'>Check out</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='d-flex align-items-center p-2 mt-3'>
                                    <span className=' parag'>${info.price} x {((checkOutValue.getTime() - checkInValue.getTime()) / 86400000) || 0} night</span>
                                    <span className=' ms-auto parag '>${(info.price * (checkOutValue.getTime() - checkInValue.getTime()) / 86400000) || 0}</span>
                                </div>
                                <div className='d-flex align-items-center p-2'>
                                    <span className=' parag'>Service charge</span>
                                    <span className=' ms-auto parag '>$30</span>
                                </div>
                                <hr className='' />
                                <div className='d-flex align-items-center p-2'>
                                    <span className='p-bold '>Total</span>
                                    <span className=' ms-auto p-bold '>${((info.price * (checkOutValue.getTime() - checkInValue.getTime()) / 86400000) + 30) || 0}</span>
                                </div>
                                <div>
                                    <button onClick={reserveHandler}
                                    className='btn btn-primary w-100 my-3 rounded-25p'>Reserve</button>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className='text-center bg-personal py-3'>
                        <SectionHeader title="Explore by types of stays" subTitle="Explore houses based on 10 types of stays" />
                        <SliderExplore />
                    </div>
                    <Newsletter />
                </div>
            </div>
            <Footer />
        </div>
    )
}
