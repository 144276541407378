import React, { useContext, useState } from 'react'
import Header from '../../Components/Header/Header'
import { AuthContext } from '../../Context'
import Input from "../../Components/Form/Input"
import { reqValid, minValid, maxValid, emailValid } from '../../Validations/rouls'
import useForm from '../../Components/MyHooks/useForm'
import Loader from '../../Components/Loader/Loader'


export default function Login() {

    const [showLogin, setShowLogin] = useState(false)

    const { register, login, isLoading } = useContext(AuthContext)

    const [formState, inputHandler] = useForm(
        {
            username: {
                value: "",
                isValid: false
            },
            email: {
                value: "",
                isValid: false
            },
            password: {
                value: "",
                isValid: false
            },
            loginUsername: {
                value: "",
                isValid: false
            },
            loginPassword: {
                value: "",
                isValid: false
            },
        }
        , false
    )

    const createUser = async (event) => {
        event.preventDefault()

        const user = {
            username: formState.inputs.username.value,
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
        }

        register(user)
    }

    const loginUser = async (event) => {
        event.preventDefault()

        const user = {
            email: formState.inputs.loginUsername.value,
            password: formState.inputs.loginPassword.value,
        }

        login(user)
    }

    return (
        <>
            <Header />
            <div className='container'>
                <div className='d-flex justify-content-center align-items-center'>

                    <div className={!showLogin ? `my-5 p-1 col-md-6 col-sm-12 col-xs-12` : "d-none"}>
                        <h2 className='h2 text-center p-3'>Signup</h2>
                        <form onSubmit={createUser}
                            className='border p-5 rounded'>
                            <div className="mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label">Username</label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    id="username"
                                    validations={[
                                        reqValid(),
                                        minValid(5),
                                        maxValid(25)
                                    ]}
                                    inputHandler={inputHandler}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    id="email"
                                    validations={[
                                        reqValid(),
                                        minValid(5),
                                        maxValid(25),
                                        emailValid()
                                    ]}
                                    inputHandler={inputHandler}
                                />
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    id="password"
                                    validations={[
                                        reqValid(),
                                        minValid(5),
                                        maxValid(25)
                                    ]}
                                    inputHandler={inputHandler}
                                />
                            </div>
                            <div className="mb-4 form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                            </div>
                            {isLoading ? (
                                <button type="submit" className="btn btn-primary w-100 rounded-50p" disabled><Loader /></button>
                            ) : (
                                <button type="submit" className="btn btn-primary w-100 rounded-50p">Sign up</button>
                            )}


                        </form>
                        <p className='parag text-center p-3'>Already have an account?
                            <span onClick={() => setShowLogin(true)}
                                className='cursor-p p-bold'> Log in</span>
                        </p>
                    </div>

                    <div className={showLogin ? `my-5 p-1 col-md-6 col-sm-12 col-xs-12` : "d-none"}>
                        <h2 className='h2 text-center p-3'>Log in</h2>
                        <form onSubmit={loginUser}
                            className='border p-5 rounded'>
                            <div className="mb-4">
                                <label htmlFor="exampleInputEmail1" className="form-label">Username</label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    id="loginUsername"
                                    validations={[
                                        reqValid(),
                                        minValid(5),
                                        maxValid(25)
                                    ]}
                                    inputHandler={inputHandler}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    id="loginPassword"
                                    validations={[
                                        reqValid(),
                                        minValid(5),
                                        maxValid(25)
                                    ]}
                                    inputHandler={inputHandler}
                                />
                            </div>
                            <div className="mb-4 form-check">

                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                            </div>
                            {isLoading ? (
                                <button type="submit" className="btn btn-primary w-100 rounded-50p" disabled><Loader /></button>
                            ) : (
                            <button type="submit" className="btn btn-primary w-100 rounded-50p">Log in</button>
                            )}
                        </form>
                        <p className='parag text-center p-3'>Dont have an account?
                            <span onClick={() => setShowLogin(false)}
                                className='cursor-p p-bold'> Sign up</span>
                        </p>
                    </div>

                </div>
            </div>
        </>
    )
}
