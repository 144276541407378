import React, { useEffect, useState } from 'react'
import PlaceCard from '../Cards/PlaceCard'

export default function StaysModal({country}) {

    const [stays, setStays] = useState([])

    const getAllStays = async () => {
        const res = await fetch("https://airbnb-api-six.vercel.app/api/posts" , {
            method : "POST"
          })
          const data = await res.json()
          setStays(data.posts)
    }

    useEffect(() => {
        getAllStays()
    } , [country])

    return (
        <div className='container my-3 mx-auto'>
            <div className='d-flex align-items-center justify-content-between flex-wrap'>
                {stays.map(stay => <PlaceCard {...stay} key={stay._id}/>)}
            </div>
        </div>
    )
}
