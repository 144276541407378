import React, { useState } from 'react'

export default function FlightCard() {

    const [show, setShow] = useState(false)

    return (
        <>
            <div className=' border bg-white my-5 rounded-25p p-3' style={{ overflow: "hidden" }}>
                <div className='row'>
                    <div className='col-lg-2 col-sm-6 my-1 col-12 mb-2'>
                        <img src="/img/b1.png" className="rounded-50p" style={{ width: 50, height: 50 }} alt="..." />
                    </div>
                    <div className='col-lg-2 col-sm-6 my-1 col-12 mb-2'>
                        <h5 className="card-title  h5">11:00 - 20:00</h5>
                        <p className='parag'>Korean Air</p>
                    </div>
                    <div className='col-lg-3 col-sm-6 my-1 col-12 mb-2'>
                        <h5 className="card-title  h5">HND -SIN</h5>
                        <p className='parag'>7 hours 15 minutes</p>
                    </div>
                    <div className='col-lg-3 col-sm-6 my-1 col-12'>
                        <h5 className="card-title  h5">1 stop</h5>
                        <p className='parag'>2 hours 15 minutes BBK</p>
                    </div>
                    <div className='col-lg-2 col-sm-6 my-1 col-12 mb-2 d-flex '>
                        <div>
                            <h5 className="text-success card-title h4">$3,380</h5>
                            <p className='parag'>round-trip</p>
                        </div>
                        <span onClick={() => setShow(prev => !prev)}
                            className="material-symbols-outlined cursor-p ms-3">
                            keyboard_arrow_down
                        </span>
                    </div>
                </div>

                {show &&
                    <div className='container'>
                        <div className='border rounded-25p  bg-white my-3 container py-3'>
                            <div className='row ' style={{ overflow: "hidden" }}>
                                <div className='col-lg-6 col-sm-12 col-12 mb-2'>
                                    <div className='d-flex row align-items-center '>
                                        <div className='col-lg-3 col-sm-12 col-12 py-3'>
                                            <img src="/img/b1.png" className="rounded-50p " style={{ width: 50, height: 50 }} alt="..." />
                                        </div>
                                        <div className='col-xl-8 col-lg-9 col-sm-12 col-12 mb-2 border-end'>
                                            <div className='d-flex align-items-center mb-3'>
                                                <span className="material-symbols-outlined me-2 parag">
                                                    circle
                                                </span>
                                                <div>
                                                    <p className='parag'>Monday, August 12 · 10:00</p>
                                                    <p className='p-bold lh-base'>Tokyo International Airport (HND)</p>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center mb-3'>
                                                <span className="material-symbols-outlined me-2 parag">
                                                    circle
                                                </span>
                                                <div>
                                                    <p className='parag'>Monday, August 16 · 10:00</p>
                                                    <p className='p-bold lh-base'>London International Airport (SIN)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-12 col-12 mb-2'>
                                    <p className="card-title parag">Trip time: 7 hours 45 minutes</p>
                                    <p className='parag'>ANA · Business class · Boeing 787 · NH 847</p>
                                </div>
                            </div>
                            <div className='container text-center my-3 py-3 border-top border-bottom'>
                                <p className='parag'>
                                    Transit time: 15 hours 45 minutes - Bangkok (BKK)
                                </p>
                            </div>
                            <div className='row ' style={{ overflow: "hidden" }}>
                                <div className='col-lg-6 col-sm-12 col-12 mb-2'>
                                    <div className='d-flex row align-items-center '>
                                        <div className='col-lg-3 col-sm-12 col-12 py-3'>
                                            <img src="/img/b1.png" className="rounded-50p " style={{ width: 50, height: 50 }} alt="..." />
                                        </div>
                                        <div className='col-xl-8 col-lg-9 col-sm-12 col-12 mb-2 border-end'>
                                            <div className='d-flex align-items-center mb-3'>
                                                <span className="material-symbols-outlined me-2 parag">
                                                    circle
                                                </span>
                                                <div>
                                                    <p className='parag'>Monday, August 12 · 10:00</p>
                                                    <p className='p-bold lh-base'>Tokyo International Airport (HND)</p>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center mb-3'>
                                                <span className="material-symbols-outlined me-2 parag">
                                                    circle
                                                </span>
                                                <div>
                                                    <p className='parag'>Monday, August 12 · 10:00</p>
                                                    <p className='p-bold lh-base'>London International Airport (SIN)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-12 col-12 mb-2'>
                                    <p className="card-title parag">Trip time: 7 hours 45 minutes</p>
                                    <p className='parag'>ANA · Business class · Boeing 787 · NH 847</p>
                                </div>
                            </div>
                        </div>


                    </div>}
            </div>

        </>
    )
}
