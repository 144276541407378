import React from 'react'

export default function TopicBox({img , title , subTitle}) {
  return (
    <div className='d-flex p-3 border-bottom'>
        <div>
            <img src={img} style={{ width: 60, height: 60 }} className="rounded"/>
        </div>
        <div className='ms-3'>
            <h4 className='h6'>{title}</h4>
            <p className='parag'>{subTitle}</p>
        </div>
    </div>
  )
}
