import React, { useEffect, useState } from 'react'
import AuthorCard from '../../Components/Cards/AuthorCard'
import Header from '../../Components/Header/Header'
import MainDisplay from '../../Components/MainDisplay/MainDisplay'
import Newsletter from '../../Components/Newsletter/Newsletter'
import StayPlaces from '../../Components/StayPlaces/StayPlaces'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import SliderExplore from '../../Components/SliderExplore/SliderExplore'
import Footer from '../../Components/Footer/Footer'



export default function Stay() {

    const [users, setUsers] = useState([])


    const getAllUsers = async () => {

        const res = await fetch("https://airbnb-api-six.vercel.app/api/users", {
          method: "POST",
          body: JSON.stringify({ code: 200 })
        })
        const data = await res.json()
        setUsers(data.users)
      }


    useEffect(() => {
        getAllUsers()
    }, [])

    return (
        <div>
            <Header />
            <MainDisplay img="h1.png">
                <div className='col-md-6 col-sm-12'>
                    <h2 className='h0'>Tokyo, Jappan</h2>
                    <div className='my-5 lead d-flex align-items-center'>
                        <div className='lead d-flex align-items-center'>
                            <span className="material-symbols-outlined">
                                map
                            </span>
                            <span className='parag ms-2'>Jappan</span>
                        </div>
                        <div className='lead ms-4 d-flex align-items-center'>
                            <span className="material-symbols-outlined">
                                home
                            </span>
                            <span className='parag ms-2'>712 properties</span>
                        </div>
                    </div>
                   
                </div>
            </MainDisplay>
            <div className='bg-light rounded py-3'>
                <SectionHeader title="Stays inTokyo" subTitle="233 stays . Aug 12 - 18 . 2 Guests" />
                <StayPlaces />
            </div>

            <div className='text-center bg-personal py-3'>
                <SectionHeader title="Explore by types of stays" subTitle="Explore houses based on 10 types of stays" />
                <SliderExplore />
            </div>
            <Newsletter />
            <div className='text-center bg-personal py-3'>
                <SectionHeader title="Top 10 author of the month" subTitle="Rating based on customer reviews" />
                <div className='container '>
                    <div className='row'>
                        {users.map((user , index) => {
                            if(index < 12 ){
                               return <AuthorCard key={user._id} img="q1.jpg" {...user} />
                            } 
                        })}
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    )
}
