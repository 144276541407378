import React from 'react'

export default function NearCard({ img, title }) {
    return (
        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xm-12 my-3 p-3'>
            <div className=" d-flex border p-3 rounded-50p bg-white align-items-center" >
                <img src={`/img/${img}`} className="card-img-top rounded-50" alt="..." style={{ width: 100, height: 100, margin: "0 auto" }} />
                <div className='text-start'>
                    <h5 className=" my-2 p-bold">{title}</h5>
                    <p className=" parag">18 minutes drive</p>
                    <div className='bg-btn rounded-50p p-1 text-warning w-50 small m-2'>
                        1895
                    </div>
                </div>
            </div>
        </div>
    )
}
