
export const reqValid = () => {
    return {
        name:"reqValid"
    }
}

export const minValid = min => {
    return{
        name:"minValid",
        min
    }
}

export const maxValid = max => {
    return {
        name:"maxValid" ,
        max
    }
}

export const emailValid = max => {
    return {
        name:"emailValid" 
    }
}