import React from 'react'
import CityCard from '../../Components/Cards/CityCard'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Newsletter from '../../Components/Newsletter/Newsletter'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import Slider4 from '../../Components/Slider4/Slider4'

export default function About() {
    return (
        <>
            <Header />
            <div className='container'>
            <div className=' py-5 row'>
                <div className='col-md-6 col-sm-12'>
                    <SectionHeader title="About Us" subTitle="We’re impartial and independent, and every day we
                     create distinctive, world-class programmes and content which inform, educate and entertain 
                    millions of people in the around the world." />
                </div>
                <div className='col-md-6 col-sm-12'>
                    <img src='/img/f5.png' />
                </div>
            </div></div>

            <div className=''>
                <SectionHeader title="Founder" subTitle="We’re impartial and independent,
                 and every day we create distinctive,
                 world-class programmes and content" />
            </div>
            <div className='container my-3'>
                <div className='row' >
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3'>
                        <CityCard img="/img/po.jpg" />
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3'>
                        <CityCard img="/img/as.jpg" />
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3'>
                        <CityCard img="/img/er.jpg" />
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3'>
                        <CityCard img="/img/user.jpg" />
                    </div>
                </div>
            </div>

            <Newsletter />


            <div className='container py-5 my-3'>
                <div className='row'>
                    <div className='col-md-6 col-sm-12 text-start d-flex align-items-center justify-content-center'>
                        <div className=''>
                            <img src='/img/logo.png' className='w-25' />
                            <h2 className='h2 my-4'>Why did you choose us?</h2>
                            <p className='parag my-4'>Accompanying us, you have a trip full of experiences.
                                With Chisfis, booking accommodation, resort villas, hotels, private houses,
                                apartments... becomes fast, convenient and easy.
                            </p>
                            <button className='btn btn-primary rounded-50p p-2'>Become an author</button>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <img src='/img/p1.png' />
                    </div>
                </div>
            </div>


            <div className='text-center bg-light py-3'>
                <SectionHeader title="Good news from far away" subTitle="Let's see what people think of Chisfis" />
                <div className='container'>
                    <Slider4 />
                </div>
            </div>

            <Footer />
        </>
    )
}
