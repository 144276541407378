import React from 'react'

export default function AuthorCard({ img , username  }) {
    return (
        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 my-3 p-3'>
            <div className="card text-center border-none p-3 rounded-50p bg-white" >
                <img src={`/img/${img}`} className="card-img-top rounded-50" alt="..." style={{ width: 100, height: 100, margin: "0 auto" }} />
                <div className="card-body">
                    <h5 className="card-title my-2 p-bold">{username}</h5>
                    <p className="card-text parag">New York</p>
                    <div className='bg-btn p-3 mt-3 rounded-50p d-flex align-items-center justify-content-center'>4.9 
                    <span className="material-symbols-outlined ms-3 text-warning">
                        star
                    </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
