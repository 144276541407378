import React, { useEffect, useState } from 'react'
import PlaceCard from '../Cards/PlaceCard'

import "./Places.css"

export default function Places({stays}) {

    const [show, setShow] = useState("")
    const [count , setCount] = useState(8)

    return (
        <div className='container my-3'>
            <div className='my-3'>
                <ul className='d-flex align-items-center flex-wrap'>
                    <li className={`mx-3 h5 cursor-p ${show === "1" && "list-city-active"}`}
                        onClick={() => setShow("1")}>New York</li>
                    <li className={`mx-3 h5 cursor-p ${show === "2" && "list-city-active"}`}
                        onClick={() => setShow("2")}>Tokyo</li>
                    <li className={`mx-3 h5 cursor-p ${show === "3" && "list-city-active"}`}
                        onClick={() => setShow("3")}>Paris</li>
                    <li className={`mx-3 h5 cursor-p ${show === "4" && "list-city-active"}`}
                        onClick={() => setShow("4")}>London</li>
                </ul>
            </div>
            <div className='d-flex align-items-center justify-content-between flex-wrap'>
                {stays.map((stay , index) => {
                    if(index < count){
                        return <PlaceCard {...stay} key={stay._id}/>
                    }
                   
                })}
            </div>
            <div className='d-flex justify-content-center align-items-center p-5'>
                {count == 8 ? (
                <button onClick={() => setCount(12)}
                className='btn btn-outline-primary rounded-50p px-3'>
                    <span className='spinner-border spinner-border-sm mx-2'></span>
                    Show me more
                </button>) : (
                <></>
               )}
            </div>
        </div>
    )
}
