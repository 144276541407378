import React from 'react'

export default function post({img , title }) {
  return (
    <div className='col-lg-3 col-md-6 col-xs-12 p-3 position-relative cursor-p'>
        <img src={img} className='rounded-25p'/>
        <div className='p-3 position-absolute bottom-0 mb-3 text-white'>
            <span className='bg-primary text-light rounded-25p px-2 py-1'>Categories</span>
            <h4 className='h5 my-3'>{title}</h4>
            <span className='small'>Loca Modelini . May 20, 2022</span>
        </div>
    </div>
  )
}
