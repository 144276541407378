import React from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import EmailSection from './EmailSection'

export default function Newsletter() {
    return (
        <div className='container py-1'>
            <div className='row'>
                <div className='col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                    <div>
                        <SectionHeader title="Join our newsletter" subTitle="Read and share new perspectives on just about any topic. Everyone’s welcome." />
                        <div >
                            <span className='rounded-50p bg-light text-primary p-2 me-3'>01</span>
                            <span className='h5 me-4'>Get more discount</span>
                            <img src='/img/e3.png' style={{width:50}}/>
                        </div>
                        <div className='mt-4'>
                            <span className='rounded-50p bg-light text-danger p-2 me-3'>02</span>
                            <span className='h5'>Get premium magazines</span>
                        </div>
                        <EmailSection />
                    </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <img src='/img/e1.png' />
                </div>
            </div>
        </div>
    )
}
