import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./Mapp.css"
import L from "leaflet"

export default function Mapp() {

  const position = [51.505, -0.09]

  const markerIcon = new L.Icon({
    iconUrl:("/img/marker.png"),
    iconSize:[35,45],
    iconAnchor:[17,46],
    popupAnchor:[0,-46]
  })

  return (

    <div className="mapp-container">
      <MapContainer center={position} zoom={12} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker icon={markerIcon} position={position} >
          <Popup>
          Romantic view in city. <br /> Easily customizable.
          </Popup>
        </Marker>
        <Marker icon={markerIcon} position={[51.509, -0.07]}>
          <Popup>
          Room view in city. <br /> Easily customizable.
          </Popup>
        </Marker>
        <Marker icon={markerIcon} position={[51.500, -0.05]}>
          <Popup>
          House in city. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}