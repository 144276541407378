import React from 'react'

export default function CenterCard({img}) {
    return (
        <div className='col-lg-4 col-md-12 col-sm-12 my-3 p-5'>
            <div className="card text-center border-none" >
                <img src={`/img/${img}`} className="card-img-top rounded-50p" alt="..." style={{width:200 , height:200 , margin:"0 auto"}}/>
                <div className="card-body">
                    <h5 className="card-title my-3 p-bold">Book & relax</h5>
                    <p className="card-text parag">Some quick example text to build on title and make up the bulk of the card's content.</p>
                </div>
            </div>
        </div>
    )
}
