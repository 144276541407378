import React from 'react'
import StaysModal from '../Modals/StaysModal'
import TravelSearch from '../TravelSearch/TravelSearch'
import "./MainDisplay.css"

export default function MainDisplay({ children, img }) {
  return (
    <>
      <div className='main-display-div' >
        <div className='main-display-bg-light'>
          <div className=' mb-5 py-5 container'>
            <div className='row  '>
              {children}
              <div className='col-md-6 col-sm-12 '>
                <img src={`/img/${img}`} />
              </div>
            </div>
            <TravelSearch />
          </div>
        </div>

      </div>
     
    </>
  )
}
