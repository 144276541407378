import React from 'react'

export default function BlogBox({ img, title }) {
  return (
    <div className='d-flex my-5 row p-3'>
      <div className='col-lg-4 col-xs-12 d-md-none d-sm-block'>
        <img src={img} style={{ width: "100%", height: "100%" }} className="rounded-25p" />
      </div>
      <div className='d-flex flex-column justify-content-between p-2 col-lg-8 col-xs-12'>
        <h3 className='h5'>{title}</h3>
        <p className='parag my-2'>In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus,
          aliquet at, feugiat non, pretium quis,
        </p>
        <div className='d-flex align-items-center'>
          <img src='/img/as.jpg' style={{ width: 40, height: 40 }} className="rounded-50 " />
          <p className='mx-2 p-bold'>Flanconar Agnes </p>
          <span className='parag'>May 20, 2021</span>
        </div>
      </div>
      <div className='col-lg-4 col-xs-12 d-md-block d-sm-none d-none-600'>
        <img src={img} style={{ width: 200, height: 200 }} className="rounded-25p" />
      </div>
    </div>
  )
}
