import React, { useState } from 'react'
import "./Gallery.css"

export default function Gallery({ img, images }) {

        const [show , setShow] = useState("")

    return (
        <div className='p-3 overflow-x-hidden my-3'>
            {show ? (
            <div className='position-absolute w-100 h-100 bg-dark top-0 left-0 z-3 p-5'>
                <button className='btn close-btn text-white mt-5' onClick={() => setShow("")}>X</button>
                <img src={show} className='p-1 rounded-25p w-100' />
            </div>) : (
            <div className='row d-flex align-items-center'>
                <div className='col-lg-6 col-xs-12 col-12 position-relative'>
                    <img src={`${img}`} className='p-1 rounded-25p' onClick={() => setShow(`/img/${img}`)}/>
                    <div className='Gallery-{`/img/${img}`}div-buttun'>
                        <span className="material-symbols-outlined me-1">
                            grid_view
                        </span>
                        <span>Show all photos</span>
                    </div>
                </div>
                <div className='col-lg-6 col-xs-12 col-12'>
                    <div className='row'>
                        <div className='col-lg-6 col-xs-3 col-3'>
                            <img src={`/img/${images[0]}`} className='p-1 rounded-25p' onClick={() => setShow(`/img/${images[0]}`)}/>
                        </div>
                        <div className='col-lg-6 col-xs-3 col-3'>
                            <img src={`/img/${images[1]}`} className='p-1 rounded-25p' onClick={() => setShow(`/img/${images[1]}`)}/>
                        </div>
                        <div className='col-lg-6 col-xs-3 col-3'>
                            <img src={`/img/${images[2]}`} className='p-1 rounded-25p' onClick={() => setShow(`/img/${images[2]}`)}/>
                        </div>
                        <div className='col-lg-6 col-xs-3 col-3'>
                            <img src={`/img/${images[3]}`} className='p-1 rounded-25p' onClick={() => setShow(`/img/${images[3]}`)}/>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    )
}
