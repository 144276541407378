import './App.css';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';
import { useEffect } from 'react';

function App() {

  const router = useRoutes(routes)

  useEffect(() => {
    window.scrollTo(0 , 0)
  } ,[window.location.href])

  return (
    <div>
      {router}
    </div>
  );
}

export default App;
