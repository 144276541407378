import React from 'react'
import { Link } from 'react-router-dom'
import BlogBox from '../../Components/BlogBox/BlogBox'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import Newsletter from '../../Components/Newsletter/Newsletter'
import PostBox from '../../Components/PostBox/PostBox'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import TagBox from '../../Components/TagBox/TagBox'
import TopicBox from '../../Components/TopicBox/TopicBox'

export default function Blog() {
    return (
        <>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 p-3'>
                        <div className=' my-4'>
                            <div>
                                <img src='/img/f4.jpg' style={{ width: "100%", height: "80%" }} className="rounded-25p my-3" />
                            </div>
                            <div className='d-flex flex-column justify-content-between p-2'>
                                <Link to="/blog-single/lenova">
                                    <h3 className='h4'>Lenovo’s smarter devices stoke professional passions</h3>
                                    <p className='parag my-4'>Aenean lectus. Pellentesque eget nunc. Donec quis orci
                                        eget orci vehicula condimentum.
                                    </p>
                                </Link>
                                <div className='d-flex align-items-center'>
                                    <img src='/img/po.jpg' style={{ width: 40, height: 40 }} className="rounded-50 " />
                                    <p className='mx-2 p-bold'>Flanconar Agnes </p>
                                    <span className='parag'>May 20, 2021</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 p-3'>
                        <Link to="/blog-single/lenova"><BlogBox img="/img/f1.jpg" title="How AI and Teams are benefitting the littlest of patients" /></Link>
                        <Link to="/blog-single/lenova"> <BlogBox img="/img/f2.jpg" title="Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat" /></Link>
                        <Link to="/blog-single/lenova"> <BlogBox img="/img/f3.jpg" title="How AI and Teams are benefitting the littlest of patients" /></Link>
                    </div>
                </div>
                <div className='my-3'>
                    <img src='/img/d1.png' />
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-sm-12 p-2'>
                        <SectionHeader title="Latest Articles" subTitle="Popular places to stay that Chisfis recommends for you" />
                        <BlogBox img="/img/f4.jpg" title="How AI and Teams are benefitting the littlest of patients" />
                        <BlogBox img="/img/utah.jpg" title="Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat" />
                        <BlogBox img="/img/paris.jpg" title="How AI and Teams are benefitting the littlest of patients" />
                        <BlogBox img="/img/s1.jpg" title="Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat" />
                        <BlogBox img="/img/f1.jpg" title="How AI and Teams are benefitting the littlest of patients" />
                        <BlogBox img="/img/f2.jpg" title="Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat" />
                        <BlogBox img="/img/f3.jpg" title="How AI and Teams are benefitting the littlest of patients" />
                    </div>
                    <div className='col-lg-4 col-sm-12 p-2 '>
                        <div className='border p-3 rounded-25p bg-light my-5'>
                            <div className='d-flex align-items-center py-3 border-bottom'>
                                <h3 className='h4'>Discover more tags</h3>
                                <p className='p-bold text-primary ms-auto cursor-p'>view all</p>
                            </div>
                            <div className='py-3'>
                                <TagBox title="Outdoors" number="14" />
                                <TagBox title="Health" number="8" />
                                <TagBox title="Toys" number="11" />
                                <TagBox title="Outdoors" number="4" />
                                <TagBox title="Books" number="7" />
                                <TagBox title="Outdoors" number="14" />
                                <TagBox title="Beauty" number="10" />
                                <TagBox title="Outdoors" number="8" />
                                <TagBox title="Outdoors" number="4" />
                                <TagBox title="Outdoors" number="14" />
                                <TagBox title="Health" number="8" />
                                <TagBox title="Toys" number="11" />
                                <TagBox title="Outdoors" number="4" />
                            </div>
                        </div>
                        <div className='border p-3 rounded-25p bg-light my-4'>
                            <div className='d-flex align-items-center py-3 border-bottom'>
                                <h3 className='h4'>Trending topic</h3>
                                <p className='p-bold text-primary ms-auto cursor-p'>view all</p>
                            </div>
                            <div className=''>
                                <TopicBox img="/img/f1.jpg" title="Garden" subTitle="13 Articles" />
                                <TopicBox img="/img/f3.jpg" title="Jewelry" subTitle="10 Articles" />
                                <TopicBox img="/img/f1.jpg" title="Garden" subTitle="8 Articles" />
                                <TopicBox img="/img/f2.jpg" title="Tools" subTitle="9 Articles" />
                                <TopicBox img="/img/f1.jpg" title="Garden" subTitle="21 Articles" />
                            </div>
                        </div>
                        <div className='border p-3 rounded-25p bg-light my-4'>
                            <div className='d-flex align-items-center py-3 border-bottom'>
                                <h3 className='h4'>Popular Posts</h3>
                                <p className='p-bold text-primary ms-auto cursor-p'>view all</p>
                            </div>
                            <div className=''>
                                <PostBox img="/img/f1.jpg" title="In hac habitasse platea dictumst. Etiam faucibus cursus urna" username="Fones Milni" />
                                <PostBox img="/img/f3.jpg" title="Aenean lectus. Pellentesque eget nunc. Donec quis orci eget" username="Radi Biot" />
                                <PostBox img="/img/f2.jpg" title="In hac habitasse platea dictumst. Etiam faucibus cursus urna" username="Katy scut" />
                                <PostBox img="/img/f1.jpg" title="Aenean lectus. Pellentesque eget nunc. Donec quis orci eget" username="Roy Kin" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-center my-3'>
                    <button className='btn btn-primary btn-lg rounded-25p'>Show me more</button>
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    )
}
