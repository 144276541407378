import React from 'react'

export default function SubsBox({ cost }) {
    return (
        <div className='col-lg-4 col-md-6 col-sm-12 text-start p-3'>
            <div className='border p-3 rounded-25p'>
                <p className='parag'>Starter</p>
                <div>
                    <span className='h2'>${cost}</span>
                    <span className='parag'>/mo</span>
                </div>
                <div className='d-flex align-items-center mt-4'>
                    <span className="material-symbols-outlined text-primary me-3">
                        check
                    </span>
                    <span className='parag '>Everything in Starter</span>
                </div>
                <div className='d-flex align-items-center mt-3'>
                    <span className="material-symbols-outlined text-primary me-3">
                        check
                    </span>
                    <span className='parag '>Everything in Starter</span>
                </div>
                <div className='d-flex align-items-center mt-3'>
                    <span className="material-symbols-outlined text-primary me-3">
                        check
                    </span>
                    <span className='parag '>Everything in Starter</span>
                </div>
                <div className='d-flex align-items-center mt-3'>
                    <span className="material-symbols-outlined text-primary me-3">
                        check
                    </span>
                    <span className='parag '>Everything in Starter</span>
                </div>
                <div>
                    <button className='btn btn-primary mt-4 w-100 rounded-25p'>Submit</button>
                </div>
                <p className=' parag small mt-2'>Literally you probably haven't heard of them jean shorts.</p>
            </div>
        </div>
    )
}
