import React, { useState } from 'react'
import SectionHeader from '../SectionHeader/SectionHeader'
import "./Videos.css"

export default function Videos() {

    const [video, setVideo] = useState("https://www.youtube.com/embed/iptLKUr5VOw?t=39")


    return (
        <div>
            <SectionHeader title="The Videos" subTitle="Check out our hottest videos. View more and share more new perspectives on just about any topic. Everyone’s welcome." />
            <div className='container py-3'>
                <div className='row'>
                    <div className='col-md-10 col-sm-12'>
                        <div>
                            <iframe width="90%" height="630" className='rounded-50p'
                                src={video}>
                            </iframe>
                        </div>
                    </div>
                    <div className='col-md-2 col-sm-12 '>
                        <div className='mb-3 mx-auto position-relative'>
                            <img 
                                className='rounded-50p' style={{ width: 210, height: 140 }}
                                src="/img/f1.jpg" />
                            <div onClick={() => setVideo("https://www.youtube.com/embed/7cvo4yVEy8E")}
                            className="play-button">
                                <span className="material-symbols-outlined play-circles">
                                    play_arrow
                                </span>
                            </div>
                        </div>
                        <div className='mb-3 mx-auto position-relative'>
                            <img 
                                className='rounded-50p' style={{ width: 210, height: 140 }}
                                src="/img/paris.jpg" />
                                <div onClick={() => setVideo("https://www.youtube.com/embed/zTjnQ0XlAc4")}
                                className="play-button">
                                <span className="material-symbols-outlined play-circles">
                                    play_arrow
                                </span>
                            </div>
                        </div>
                        <div className='mb-3 mx-auto position-relative'>
                            <img 
                                className='rounded-50p' style={{ width: 210, height: 140 }}
                                src="/img/q8.jpg" />
                                <div onClick={() => setVideo("https://www.youtube.com/embed/aaGJ_fDcceg")}
                                className="play-button">
                                <span className="material-symbols-outlined play-circles">
                                    play_arrow
                                </span>
                            </div>
                        </div>
                        <div className='mb-3 mx-auto position-relative'>
                            <img 
                                className='rounded-50p' style={{ width: 210, height: 140 }}
                                src="/img/q10.png" />
                                <div onClick={() => setVideo("https://www.youtube.com/embed/m6gTVppI-10")}
                                className="play-button">
                                <span className="material-symbols-outlined play-circles">
                                    play_arrow
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
