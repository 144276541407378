import React, { useRef, useState } from "react";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "./Slider4.css";

export default function App() {
    return (
        <>
            <Swiper  pagination={{
          clickable: true,
        }}
        modules={[Pagination , Navigation]}
        navigation={true}
            className="mySwiper slider4-swiper">
                <SwiperSlide className="slider4-swiper-slide">
                    <div className="text-center">
                        <div className="mx-auto my-2">
                            <img src="/img/user1.jpg" />
                        </div>
                        <p className="p-4 lead" style={{ maxWidth: 500 }}>booking accommodation resort villas, hotels, private houses, apartments... , convenient and easy.</p>
                        <h3 className="mb-3 h5">Benita bounochi</h3>
                        <div className="parag d-flex align-items-center justify-content-center">
                            <span className="material-symbols-outlined">
                                location_on
                            </span>
                            <p>Tokyo</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slider4-swiper-slide">
                    <div className="text-center">
                        <div className="mx-auto my-2">
                            <img src="/img/user2.jpg" />
                        </div>
                        <p className="p-4 lead" style={{ maxWidth: 500 }}>booking accommodation resort villas, hotels, private houses, apartments... , convenient and easy.</p>
                        <h3 className="mb-3 h5">Mickel Fidix</h3>
                        <div className="parag d-flex align-items-center justify-content-center">
                            <span className="material-symbols-outlined">
                                location_on
                            </span>
                            <p>Paris</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="slider4-swiper-slide">
                    <div className="text-center">
                        <div className="mx-auto my-2">
                            <img src="/img/user3.jpg" />
                        </div>
                        <p className="p-4 lead" style={{ maxWidth: 500 }}>booking accommodation resort villas, hotels, private houses, apartments... , convenient and easy.</p>
                        <h3 className="mb-3 h5">Gabri Modes</h3>
                        <div className="parag d-flex align-items-center justify-content-center">
                            <span className="material-symbols-outlined">
                                location_on
                            </span>
                            <p>Madrid</p>
                        </div>
                    </div>
                </SwiperSlide>
                
            </Swiper>
        </>
    );
}
