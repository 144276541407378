import "./Loader.css"

export default function Loader() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className='loader-main'>
        <div className='loader-div'></div>
      </div>
    </div>
  )
}
