import React, { useEffect, useState } from 'react'
import AuthorCard from '../../Components/Cards/AuthorCard'
import Header from '../../Components/Header/Header'
import MainDisplay from '../../Components/MainDisplay/MainDisplay'
import Newsletter from '../../Components/Newsletter/Newsletter'
import StayPlaces from '../../Components/StayPlaces/StayPlaces'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import Footer from '../../Components/Footer/Footer'
import FlightsBoxes from '../../Components/FlightsBoxes/FlightsBoxes'
import Slider3 from '../../Components/Slider3/Slider3'



export default function FlightsLists() {

    const [users, setUsers] = useState([])


    const getAllUsers = async () => {
        const res = await fetch("https://airbnb-api-six.vercel.app/api/users", {
            method: "POST",
            body: JSON.stringify({ code: 200 })
        })
        const data = await res.json()
        setUsers(data.users)
    }


    useEffect(() => {
        getAllUsers()
    }, [])

    return (
        <div>
            <Header />
            <MainDisplay img="b2.png">
                <div className='col-md-6 col-sm-12'>
                    <h2 className='h0'>London ,Tokyo</h2>
                    <p className='my-5 lead p-line-height pe-5'>
                        Jappan
                    </p>

                </div>
            </MainDisplay>
            <div className=' rounded py-3'>
                <SectionHeader title="Singapore - Tokyo" subTitle="23 flights . round trip . 2 Guests" />
                <FlightsBoxes />
            </div>

            <div className='text-center bg-personal py-3'>
                <SectionHeader title="Suggestions for discovery" subTitle="Popular places to stay that Chisfis recommends for you" />
                <Slider3 />
            </div>


            <Newsletter />


            <Footer />
        </div>
    )
}

