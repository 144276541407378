import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../Context'
import MenuModal from '../Modals/MenuModal'
import "./Header.css"
import Loader from '../Loader/Loader'

export default function Header() {

    const [showTravelMenu, setShowTravelMenu] = useState(false)
    const [showCurrencyMenu, setShowCurrencyMenu] = useState(false)
    const [showLanMenu, setShowLanMenu] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [showMainMenu, setShowMainMenu] = useState(false)

    const [width, setWindowWidth] = useState(0)

    useEffect(() => {

        updateDimensions();

        window.addEventListener("resize", updateDimensions);
        return () =>
            window.removeEventListener("resize", updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const { userInfos, isLogin, isLoading } = useContext(AuthContext)


    return (
        <>
            {width > 800 ? (
                <div className=' py-1 border-bottom mb-3 position-sticky top-0 bg-white navbar-header-z-index '>
                    <div className="d-flex container-sm"
                        onClick={() => {
                            setShowCurrencyMenu(false)
                            setShowLanMenu(false)
                            setShowTravelMenu(false)
                        }}>
                        <Link className="navbar-brand me-4" to="/">
                            <img src='/img/logo.png' className='header-image-logo' />
                        </Link>
                        <div className="mb-2 mb-lg-0 d-flex align-items-center w-100">
                            <li className="dropdown fs-6 me-3"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowCurrencyMenu(false)
                                    setShowLanMenu(false)
                                    setShowTravelMenu(prev => !prev)
                                }}>
                                <span className="nav-link dropdown-toggle "  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Travelers
                                </span>
                                <ul className={showTravelMenu ? (`dropdown-menu show mt-3`) : (`dropdown-menu mt-3`)}>
                                    <li className='px-3 py-1'>
                                        <Link to="/stay">
                                            <div className="dropdown-item d-flex align-items-center p-3 rounded" href="#">
                                                <span className="material-symbols-outlined fs-1 me-3 text-primary">
                                                    home
                                                </span>
                                                <div>
                                                    <p className='h6'>Stays</p>
                                                    <p className='text-muted'>Stays rental description</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className='px-3 py-1'>
                                        <Link to="/flights">
                                            <div className="dropdown-item d-flex align-items-center p-3 rounded" href="#">
                                                <span className="material-symbols-outlined fs-1 me-3 text-primary">
                                                    flight
                                                </span>
                                                <div>
                                                    <p className='h6'>Flights</p>
                                                    <p className='text-muted'>Flights description</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className='px-3 py-1'>
                                        <Link to="/stay">
                                            <div className="dropdown-item d-flex align-items-center p-3 rounded" href="#">
                                                <span className="material-symbols-outlined fs-1 me-3 text-primary">
                                                    note_alt
                                                </span>
                                                <div>
                                                    <p className='h6'>Things to do</p>
                                                    <p className='text-muted'>tour and experiences</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className='px-3 py-1'>
                                        <Link to="/cars">
                                            <div className="dropdown-item d-flex align-items-center p-3 rounded" href="#">
                                                <span className="material-symbols-outlined fs-1 me-3 text-primary">
                                                    car_rental
                                                </span>
                                                <div>
                                                    <p className='h6'>Cars</p>
                                                    <p className='text-muted'>Car rental description</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><div className="dropdown-item py-3 px-4" href="#">
                                        <p className='h6'>documentation</p>
                                        <p className='text-muted'>Start integrating products and tools</p>
                                    </div></li>
                                </ul>
                            </li>
                            <li className="dropdown fs-6 ms-auto me-4 d-none-1000"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowCurrencyMenu(prev => !prev)
                                    setShowTravelMenu(false)
                                    setShowLanMenu(false)
                                }}>
                                <div className="nav-link dropdown-toggle align-items-center d-flex" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="material-symbols-outlined me-2">
                                        payments
                                    </span>
                                    <span>Currency</span>

                                </div>
                                <ul className={showCurrencyMenu ? "dropdown-menu show mt-3" : "dropdown-menu mt-3"}>
                                    <li className='p-2'><a className="dropdown-item p-2 d-flex align-items-center rounded" href="#">
                                        <span className="material-symbols-outlined fs-6 me-1">
                                            euro
                                        </span>
                                        <span>
                                            EUR
                                        </span>
                                    </a></li>
                                    <li className='p-2'><a className="dropdown-item p-2 d-flex align-items-center rounded" href="#">
                                        <span className="material-symbols-outlined fs-6 me-1">
                                            attach_money
                                        </span>
                                        <span>
                                            USD
                                        </span>
                                    </a></li>
                                    <li className='p-2'><a className="dropdown-item p-2 d-flex align-items-center rounded" href="#">
                                        <span className="material-symbols-outlined fs-6 me-1">
                                            Currency_lira
                                        </span>
                                        <span>
                                            LIR
                                        </span>
                                    </a></li>
                                    <li className='p-2'><a className="dropdown-item p-2 d-flex align-items-center rounded" href="#">
                                        <span className="material-symbols-outlined fs-6 me-1">
                                            Currency_yuan
                                        </span>
                                        <span>
                                            YUA
                                        </span>
                                    </a></li>
                                    <li className='p-2'><a className="dropdown-item p-2 d-flex align-items-center rounded" href="#">
                                        <span className="material-symbols-outlined fs-6 me-1">
                                            Currency_yen
                                        </span>
                                        <span>
                                            YEN
                                        </span>
                                    </a></li>
                                </ul>
                            </li>
                            <li className="dropdown fs-6 me-4 d-none-1000"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowCurrencyMenu(false)
                                    setShowLanMenu(prev => !prev)
                                    setShowTravelMenu(false)
                                }}>
                                <div className="nav-link dropdown-toggle align-items-center d-flex" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="material-symbols-outlined me-2">
                                        language
                                    </span>
                                    <span>language</span>
                                </div>
                                <ul className={showLanMenu ? "dropdown-menu show mt-3" : "dropdown-menu mt-3"}>
                                    <li className='px-3 py-1'>
                                        <div className=" d-flex align-items-center " href="#">
                                            <div className='p-3 dropdown-item rounded active'>
                                                <p className='h6'>English</p>
                                                <p className='text-muted'>United State</p>
                                            </div>
                                            <div className='p-3 dropdown-item rounded'>
                                                <p className='h6'>Francais</p>
                                                <p className='text-muted'>Belgique</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='px-3 py-1'>
                                        <div className=" d-flex align-items-center" href="#">
                                            <div className='p-3 dropdown-item rounded'>
                                                <p className='h6'>Espanish</p>
                                                <p className='text-muted'>Spain</p>
                                            </div>
                                            <div className='p-3 dropdown-item rounded'>
                                                <p className='h6'>Turkish</p>
                                                <p className='text-muted'>Turkey</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className='px-3 py-1'>
                                        <div className=" d-flex align-items-center" href="#">
                                            <div className='p-3 dropdown-item rounded'>
                                                <p className='h6'>Arabic</p>
                                                <p className='text-muted'>Iraq</p>
                                            </div>
                                            <div className='p-3 dropdown-item rounded'>
                                                <p className='h6'>Perstion</p>
                                                <p className='text-muted'>Iran</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="">
                                <Link to="/add-listing">
                                    <span className="btn btn-outline-secondary rounded ">List your property</span>
                                </Link>
                            </li>
                        </div>
                        <div className='d-flex align-items-center fs-5'>
                            <div className='col-md-4'>
                                <span className="material-symbols-outlined  btn icon-hover ms-3">
                                    notifications
                                </span>
                            </div>
                            
                                <div className='col-md-7'>
                                    {isLogin ? (
                                        <Link to="/account"><img src={`/img/${userInfos?.img || "user.jpg"}`} className='header-image-account ' /></Link>)
                                        : (
                                            <Link to="/login">
                                                <button className='btn btn-outline-secondary me-5' style={{minWidth:100}}>Sign up</button>
                                            </Link>
                                        )}
                                </div>
                           
                            <div className='col-md-1'>
                                <span onClick={() => setShowMainMenu(prev => !prev)}
                                    className='cursor-p'>
                                    <span className="material-symbols-outlined">
                                        menu
                                    </span>
                                </span>
                                {showMainMenu ? <MenuModal setShowMainMenu={setShowMainMenu} showMainMenu={showMainMenu} /> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='position-fixed bottom-0 w-100 bg-white p-3 border-top z-index-900'>
                    <div className='row'>
                        <div className='col text-center h6'>
                            <Link to="/">
                                <span className="material-symbols-outlined">
                                    home
                                </span>
                            </Link>
                        </div>
                        <div className='col text-center h6'>
                            <Link to="/cars">
                                <span className="material-symbols-outlined">
                                    directions_car
                                </span>
                            </Link>
                        </div>
                        <div className='col text-center h6'>
                            <Link to="/add-listing">
                                <span className="material-symbols-outlined">
                                    add
                                </span>
                            </Link>
                        </div>
                        <div className='col text-center h6'>
                            <Link to="/account">
                                <span className="material-symbols-outlined">
                                    favorite
                                </span>
                            </Link>
                        </div>
                        <div className='col text-center h6'>
                            <div className=''>
                                <span onClick={() => setShowMainMenu(prev => !prev)}
                                    className='cursor-p'>
                                    <span className="material-symbols-outlined">
                                        menu
                                    </span>
                                </span>
                                {showMainMenu ? (<div
                                    style={{ height: "100vh", width: "90%", position: "absolute", bottom: "0", right: 0 }}>
                                    <MenuModal setShowMainMenu={setShowMainMenu} showMainMenu={showMainMenu} />
                                </div>) : (<></>)}
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>
    )
}
