import React, { useContext, useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import { AuthContext } from '../../Context'
import { Toast } from '../../Components/Toast/Toast'
import CarBox from '../../Components/CarsBoxes/CarBox'
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2"


export default function Account() {
    const { userInfos, isLogin, logOut } = useContext(AuthContext)

    const [show, setShow] = useState("1")
    const [info, setInfo] = useState({ name: "", username: "", email: "" })
    const [notif, setNotif] = useState(false)
    const [title, setTitle] = useState("it,s Done")

    const navigate = useNavigate()

    const inputHandler = (e) => {
        setInfo(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    const updateUser = async () => {
        try {
            let res = await fetch(`https://airbnb-api-six.vercel.app/api/users/update/${userInfos._id}`, {
                method: "POST",
                body: JSON.stringify({
                    ...userInfos,
                    ...info,

                })
            })
            const data = await res.json()

            if (data.resulte) {
                notify("Your profile updated")
            } else {
                Swal.fire({
                    icon: "error",
                    text : "plaese trye again"
                })
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                text : "plaese trye again"
            })
        }



    }

    function notify(title) {
        setNotif(true)
        setTimeout(() => {
            setNotif(false)
            setTitle(prev => title)
        }, 2000);
    }

    if (!userInfos.email) {
        return <div>loading...</div>
    }



    return (
        <>
            <Header />
            <div className='container'>
                <Toast show={notif} title={title} />
                <ul className="nav nav-tabs mt-5">
                    <li onClick={() => setShow("1")}
                        className="nav-item">
                        <a className={show === "1" ? `nav-link text-dark active` : "nav-link text-dark"} aria-current="page" href="#">Account info</a>
                    </li>
                    <li onClick={() => setShow("2")}
                        className="nav-item ">
                        <a className={show === "2" ? `nav-link text-dark active` : "nav-link text-dark"} href="#">Save lists</a>
                    </li>
                    <li onClick={() => setShow("3")}
                        className="nav-item ">
                        <a className={show === "3" ? `nav-link text-dark active` : "nav-link text-dark"} href="#">Change password</a>
                    </li>
                    <li onClick={() => setShow("4")}
                        className="nav-item ">
                        <a className={show === "4" ? `nav-link text-dark active` : "nav-link text-dark"}>Change Billing</a>
                    </li>
                </ul>
            </div>
            <div className='container bg-light'>
                {show === "1" ? (
                    <div className='p-4'>
                        <h2 className='h2 '>Account information</h2>
                        <hr className='w-25' />
                        <div className='d-flex py-5 row'>
                            <div className='col-xs-12 col-md-2'>
                                <img src={`/img/${userInfos.img}` || "/img/q1.jpd"} style={{ width: 100, height: 100 }} className="rounded-50 me-5" />
                                <label className='label-file-input text-dark small mb-3'> Edit image
                                    <input name="img" type="file" size="60" onChange={e => setInfo(prev => {
                                        return {
                                            ...prev,
                                            img: e.target.files[0].name
                                        }
                                    })} />
                                </label>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-10 col-sm-12'>
                                <div className="mb-4">
                                    <label className="form-label">Name</label>
                                    <input name="name" value={info.name} onChange={inputHandler}
                                        type="text" className="form-control " placeholder={userInfos?.name || "NAME..."} />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Gender</label>
                                    <select className='form-select'>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Username</label>
                                    <input name="username" value={info.username} onChange={inputHandler}
                                        type="text" className="form-control" placeholder={userInfos?.username || "NAME..."} />
                                </div>
                                <div className="mb-4">
                                    <label
                                        className="form-label">Email</label>
                                    <input name="email" value={info.email} onChange={inputHandler}
                                        type="text" className="form-control" placeholder={userInfos?.email || "NAME..."} />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Date of birth</label>
                                    <input type="text" className="form-control" placeholder="07/11/1990" />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Address</label>
                                    <input type="text" className="form-control" placeholder="address" />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label">Phone number</label>
                                    <input type="text" className="form-control" placeholder="phone number" />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea className="form-control" rows="4"></textarea>
                                </div>
                                <br></br>
                                <span onClick={() => {
                                    logOut()
                                    navigate("/login")
                                }}
                                    className=' px-3 py-2 text-white bg-danger me-2 cursor-p rounded-25p mt-3'>Log out</span>

                                <span onClick={updateUser}
                                    className=' px-3 py-2 text-white bg-primary cursor-p rounded-25p mt-3'>Update info</span>

                            </div>
                        </div>
                    </div>) : show === "2" ? (
                        <div className='p-4'>
                            <h2 className='h2 '>Posts lists</h2>
                            <hr className='w-25' />
                            <div className='row'>
                                {userInfos?.posts?.map(post => (
                                    <CarBox {...post} key={post._id} />
                                ))}
                            </div>
                        </div>
                    ) : show === "3" ? (
                        <div className='p-4'>
                            <h2 className='h2 '>Update your password</h2>
                            <hr className='w-25' />
                            <div className='d-flex py-5'>
                                <div className=' col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12'>
                                    <div className="mb-4">
                                        <label className="form-label">Current password</label>
                                        <input type="text" className="form-control" placeholder="Current password" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="form-label">New password</label>
                                        <input type="text" className="form-control" placeholder="New password" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">Confirm password</label>
                                        <input type="text" className="form-control" placeholder="Confirm password" />
                                    </div>

                                    <br></br>
                                    <span className=' px-3 py-2 border text-white bg-primary cursor-p rounded-25p mt-3'>Update password</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                    <div className='p-4'>
                        <h2 className='h2 '>Payments & payouts</h2>
                        <hr className='w-25' />
                        <h5 className='h5 my-3'>Payout methods</h5>
                        <p className='parag my-5 col-xl-8 col-lg-8 col-md-10 col-sm-12'>When you receive a payment for a reservation, we call that payment to you
                            a "payout." Our secure payment system supports several
                            payout methods, which can be set up below. Go to FAQ.
                        </p>
                        <p className='parag my-5 col-xl-8 col-lg-8 col-md-10 col-sm-12'>
                            To get paid, you need to set up a payout method Airbnb releases payouts
                            about 24 hours after a guest’s scheduled check-in time. The time it takes for the funds to appear in
                            your account depends on your payout method. Learn more
                        </p>
                        <span className=' px-3 py-2 border text-white bg-primary cursor-p rounded-25p mt-4'>Add payout mothod</span>
                    </div>
                )
                }
            </div>
            <Footer />
        </>
    )
}
