

export default function validator(value, validations) {
    let arrey = [];

    validations.forEach(validation => {
        if (validation.name === "reqValid") {
            value.length == 0 && arrey.push(false)
        } 
        
        else if (validation.name === "minValid") {
            value.length < validation.min && arrey.push(false)
        }

        else if (validation.name === "maxValid") {
            value.length > validation.max && arrey.push(false)
        }

        else if (validation.name === "emailValid") {
            !value.includes("@") && arrey.push(false)
        }

    });


    if (arrey.length === 0) {
        return true
    } else {
        return false
    }

}