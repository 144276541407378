import React from 'react'
import SocialIcon from '../SocialIcon/SocialIcon'

export default function Footer() {
  return (
    <div className='mt-1 container p-2 d-flex justify-content-center align-items-center'>
      <div>
        <div className='row' >
          <div className='col-lg-3 col-6 col-xs-6 p-3'>
            <img src='/img/logo.png' className='w-25' />
            <SocialIcon title="Facebook" icon="facebook" />
            <SocialIcon title="Twitter" icon="twitter" />
            <SocialIcon title="Youtube" icon="youtube" />
            <SocialIcon title="Instagram" icon="instagram" />
          </div>
          <div className='col-lg-3  col-6 col-xs-6 p-3'>
            <label className='my-3 p-bold'>Getting started</label>
            <ul>
              <li className='mb-2 cursor-p parag'>Installation</li>
              <li className='mb-2 cursor-p parag'>Release Notes</li>
              <li className='mb-2 cursor-p parag'>Upgrade Guide</li>
              <li className='mb-2 cursor-p parag'>Browser Support</li>
              <li className='mb-2 cursor-p parag'>Editor Support</li>
            </ul>
          </div>
          <div className='col-lg-3  col-xs-6 col-6 p-3'>
            <label className='my-3 p-bold'>Explore</label>
            <ul>
              <li className='mb-2 cursor-p parag'>Developers</li>
              <li className='mb-2 cursor-p parag'>Releases</li>
              <li className='mb-2 cursor-p parag'>Learn design</li>
              <li className='mb-2 cursor-p parag'>Support</li>
              <li className='mb-2 cursor-p parag'>Best practices</li>
            </ul>
          </div>
          <div className='col-lg-3  col-xs-6 col-6 p-3'>
            <label className='my-3 p-bold'>Resources</label>
            <ul>
              <li className='mb-2 cursor-p parag'>Best practices</li>
              <li className='mb-2 cursor-p parag'>Support</li>
              <li className='mb-2 cursor-p parag'>Developers</li>
              <li className='mb-2 cursor-p parag'>Learn design</li>
              <li className='mb-2 cursor-p parag'>Releases</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
