import React from 'react'

export default function Reviews() {
    return (
        <section className='p-4 my-5 border rounded-25p'>
            <h2 className='h2 my-3 font-20-450'>Reviews (13 Reviews)</h2>
            <hr className='w-25 my-3' />

            <div className='my-3'>
                <span className="material-symbols-outlined text-warning">
                    star
                </span>
                <span className="material-symbols-outlined text-warning">
                    star
                </span>
                <span className="material-symbols-outlined text-warning">
                    star
                </span>
                <span className="material-symbols-outlined text-warning">
                    star
                </span>
                <span className="material-symbols-outlined text-warning">
                    star
                </span>
            </div>

            <div className="input-group my-4  border rounded-50p ps-3">
                <input type="text" className="form-control border-none p-3 " placeholder="Share your thoughts" aria-label="Recipient's username" aria-describedby="button-addon2" />
                <button className="btn  border-none" type="button" id="button-addon2">
                    <span className="material-symbols-outlined bg-primary text-white p-2 rounded-50p ">
                        east
                    </span>
                </button>
            </div>
            {/* comment */}
            <div className='border-bottom'>
                <div className=" d-flex  align-items-start my-3 flex-wrap" >
                    <div className=" d-flex  align-items-center me-auto mb-2">
                        <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                        <div>
                            <h5 className="p-bold py-2"> Judy Miri</h5>
                            <div className='d-flex'>

                                <div className='parag'>May 20, 2022</div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                    </div>
                </div>
                <p className='container parag m-3'>
                    There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.
                </p>
            </div>
            <div className='border-bottom'>
                <div className=" d-flex  align-items-start my-3 flex-wrap" >
                    <div className=" d-flex  align-items-center me-auto mb-2">
                        <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                        <div>
                            <h5 className="p-bold py-2"> Judy Miri</h5>
                            <div className='d-flex'>

                                <div className='parag'>May 20, 2022</div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                    </div>
                </div>
                <p className='container parag m-3'>
                    There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.
                </p>
            </div>
            <div className='border-bottom'>
                <div className=" d-flex  align-items-start my-3 flex-wrap" >
                    <div className=" d-flex  align-items-center me-auto mb-2">
                        <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                        <div>
                            <h5 className="p-bold py-2"> Judy Miri</h5>
                            <div className='d-flex'>

                                <div className='parag'>May 20, 2022</div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                    </div>
                </div>
                <p className='container parag m-3'>
                    There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.
                </p>
            </div>
            <div className='border-bottom'>
                <div className=" d-flex  align-items-start my-3 flex-wrap" >
                    <div className=" d-flex  align-items-center me-auto mb-2">
                        <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                        <div>
                            <h5 className="p-bold py-2"> Judy Miri</h5>
                            <div className='d-flex'>

                                <div className='parag'>May 20, 2022</div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                        <span className="material-symbols-outlined text-warning">
                            star
                        </span>
                    </div>
                </div>
                <p className='container parag m-3'>
                    There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.
                </p>
            </div>
            
            {/* end comment */}
            <div className='text-white'>.</div>
            <div className='my-3'>
                <span className=' p-3 border parag cursor-p rounded-25p'>View more 20 reviws</span>
            </div>
        </section>
    )
}
