import React from 'react'
import TextBox from '../TextBox/TextBox'

export default function ImageSection() {
  return (
    <div className='container my-5 '>
        <div className='row'>
            <div className='col-md-6 col-sm-12'>
                <img src='/img/bg.png' />
            </div>
            <div className='col-md-6 col-sm-12 p-3'>
                <div>
                    <p className='parag'>BENNEFITS</p>
                    <h2 className='h1 my-3'>Happening cities</h2>
                    <TextBox note="Advertising" color="blue" title="Cost-effective advertisting" detail="With a free listing, you can advertise your rental with no upfront costs"/>
                    <TextBox note="Advertising" color="green" title="Reach millions with Chisfis" detail="Millions of people are searching for unique places to stay around the world"/>
                    <TextBox note="Advertising" color="red" title="Secure and simple" detail="A Holiday Lettings listing gives you a secure and easy way to take bookings and payments online"/>
                </div>
            </div>
        </div>

    </div>
  )
}
