import React from 'react'

export default function TextBox({note , title , detail , color}) {
  return (
    <div className='my-5'>
        <div className='border d-inline-block p-2 rounded-50p' style={{color}}>{note}</div>
        <h3 className='h4 my-3'>{title}</h3>
        <p className='parag pe-5'>{detail}</p>
    </div>
  )
}
