import React from 'react'

export default function PostBox({title , username , img}) {
    return (
        <div className='d-flex my-4 row border-bottom'>
            <div className='d-flex flex-column justify-content-between p-2 col-xl-8 col-lg-12 col-sm-8 col-xs-12'>
                <div className='d-flex align-items-center mb-2'>
                    <img src='/img/as.jpg' style={{ width: 40, height: 40 }} className="rounded-50 " />
                    <p className='mx-2 p-bold'>{username}</p>
                    <span className='parag'>. May 20</span>
                </div>
                <h3 className='h6'>{title}</h3>
            </div>
            <div className='col-xl-4 col-lg-12 col-sm-4 col-xs-12 p-3'>
                <img src={img}  className="rounded-25p" />
            </div>
        </div>
    )
}
