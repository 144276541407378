import React from 'react'
import { Link } from 'react-router-dom'
import Slider2 from '../Slider2/Slider2'
import "./Cards.css"
import { useState } from 'react'

export default function PlaceCard({ img, title, _id, off, address, price }) {

    const [favorite , setFavorite] = useState(false)

    return (
        <div className='col-xl-3 col-lg-4 col-md-6 p-3 col-sm-12 ' style={{ overflow: "hidden" }}>
            <div className="card border-none shadow place-card-main-div">
                <Slider2 img={img} />
                <span className={`material-symbols-outlined place-card-fav-icon ${favorite ? "text-danger" :"text-white"}`}
                    onClick={() => setFavorite(!favorite)}
                >
                    favorite
                </span>
                {off &&
                    <button className={`btn btn-danger w-25  btn-sm  rounded-50p place-card-off-btn`}>
                        -{off}% today</button>}
                <Link to={`/stayinfo/${_id}`}>
                <div className="card-body">
                    <p className='parag'>Entire cabin . {3} beds</p>
                    <h5 className="card-title my-2 h5">{title}</h5>
                    <p className="card-text my-1 parag align-items-center d-flex">
                        <span className="material-symbols-outlined me-1">
                            location_on
                        </span>
                        {address === "no address" ? "5/37 West London" : address}
                    </p>
                </div>
                </Link>
                <div className='p-3 d-flex align-items-center'>
                    <div className="p-bold">${price}</div>
                    <div className='small parag me-auto'>/night</div>
                    <div><span className="material-symbols-outlined text-warning">
                        star
                    </span></div>
                    <div className='mx-1 p-bold'>{Math.floor(Math.random() * 5)}.9</div>
                    <div className='small parag'>({Math.floor(Math.random() * 450)})</div>
                </div>
            </div>
        </div>
    )
}
