import React, { useContext, useEffect, useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import Gallery from '../../Components/Gallery/Gallery'
import Header from '../../Components/Header/Header'
import Mapp from '../../Components/Mapp/Mapp'
import "./StayInfo.css"
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import SliderExplore from '../../Components/SliderExplore/SliderExplore'
import Newsletter from '../../Components/Newsletter/Newsletter'
import Reviews from '../../Components/Reviews/Reviews'
import { useNavigate, useParams } from 'react-router-dom'
import Counter from '../../Components/Counter/Counter'
import { AuthContext } from '../../Context'
import { Toast } from '../../Components/Toast/Toast'
import Calendar from 'react-calendar';
import "../../Components/Calender/Calender.css"



export default function StayInfo() {

    const [info, setInfo] = useState({})
    const [showGuestsModal, setShowGuestsModal] = useState(false)
    const [count, setCount] = useState(0)
    const [count1, setCount1] = useState(1)
    const [count2, setCount2] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [basket, setBasket] = useState({})
    const [value, onChange] = useState(new Date());
    const [secondValue, secondOnChange] = useState(new Date());

    const { infoId } = useParams()

    const getStayInfo = async () => {
        const res = await fetch(`https://airbnb-api-six.vercel.app/api/posts/getInfo`,{
            method : "POST",
            body:JSON.stringify({id : infoId})
        })
        const data = await res.json()
        setInfo(data.post)
    }

    const changeHandler = (e) => {
        setBasket(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    useEffect(() => {
        getStayInfo()
    }, [])

    let checkInValue = new Date(value)
    let checkOutValue = new Date(secondValue)

    let checkInDay = new Date(value).getDate()
    let checkInMonth = new Date(value).getMonth()
    let checkInYear = new Date(value).getFullYear()
    let checkOutDay = new Date(secondValue).getDate()
    let checkOutMonth = new Date(secondValue).getMonth()
    let checkOutYear = new Date(secondValue).getFullYear()

    const navigate = useNavigate()

    let { userInfos, isLogin } = useContext(AuthContext)

    const notify = () => {
        setShowModal(true)

        setTimeout(() => {
            setShowModal(false)
        }, 2000);
    }

    const reserveHandler = async () => {
        // if (isLogin) {
        //     let { data, error } = await Supabase.from("reserves")
        //         .insert({
        //             checkIn: checkInValue,
        //             checkOut: checkOutValue,
        //             category: true,
        //             dayCount: (checkOutValue.getTime() - checkInValue.getTime()) / 86400000,
        //             total: (info.price * (checkOutValue.getTime() - checkInValue.getTime()) / 86400000),
        //             guests: count + count1 + count2,
        //             token: userInfos.token,
        //             rentId: infoId,
        //             info
        //         })

        //     console.log(data, "error", error);

        //     if (error === null) {
        //         navigate("/peyment")
        //     }
        // } else {
        //     notify()
        //     navigate("/login")
        // }

    }


    return (
        <div>
            <Header />
            <Gallery img={info?.img} images={["q7.jpg", "q6.jpg", "q4.jpg", "q11.jpg"]} />
            <div onClick={() => setShowGuestsModal(false)}
                className='container my-3'>
                <Toast show={showModal} title="Please sign up" />
                <div className='row'>
                    <div className='col-lg-8 col-sm-12'>
                        <section className='p-4 my-5 border rounded-25p'>
                            <div className='d-flex align-items-center flex-wrap mb-2'>
                                <span className='text-primary bg-personal py-2 px-3 mb-3 rounded-25p'>Wooden house</span>
                                <span className='ms-auto me-3 d-flex align-items-center mb-3'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        ios_share
                                    </span>
                                    <span className='parag fs-6'>Share</span>
                                </span>
                                <span className='d-flex align-items-center mb-3 d-none-400'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        favorite
                                    </span>
                                    <span className='parag fs-6'>Save</span>
                                </span>
                            </div>
                            <h2 className='h1 mb-3'>{info?.title}</h2>
                            <div className='py-3 d-flex align-items-center flex-wrap'>
                                <div>
                                    <span className="material-symbols-outlined text-warning">
                                        star
                                    </span>
                                </div>
                                <div className='mx-1 p-bold'>{info?.rate || "4.6"}</div>
                                <div className='small parag me-5'>(153)</div>
                                <p className="card-text my-1 parag align-items-center d-flex">
                                    <span className="material-symbols-outlined me-1">
                                        location_on
                                    </span>
                                    {info?.address === "no address" ? "26/14 West Paris" : info?.address}
                                </p>
                            </div>
                            <div className=" d-flex  align-items-center my-3 flex-wrap" >
                                <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                                <p className=" parag me-1">Hosted by</p>
                                <h5 className="p-bold"> Kevin Francis</h5>
                            </div>
                            <div className='d-flex align-items-center mt-3 py-3 border-top row'>

                                <span className='d-flex align-items-center col-lg-3 col-6 '>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        person
                                    </span>
                                    <span className='parag fs-6 mx-1'>6 guests</span>
                                </span>
                                <span className='d-flex align-items-center  col-lg-3 col-6'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        bed
                                    </span>
                                    <span className='parag fs-6 mx-1'>7 beds</span>
                                </span>
                                <span className='d-flex align-items-center  col-lg-3 col-6'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        bathtub
                                    </span>
                                    <span className='parag fs-6 mx-1'>3 baths</span>
                                </span>
                                <span className='d-flex align-items-center  col-lg-3 col-6'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        door_open
                                    </span>
                                    <span className='parag fs-6 mx-1'>4 bedrooms</span>
                                </span>
                            </div>

                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Stay information</h2>
                            <hr className='w-25 my-4' />
                            <p className='parag'>Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides accommodation,
                                an outdoor swimming pool, a bar, a shared lounge, a garden and barbecue facilities.
                                Complimentary WiFi is provided.
                            </p>
                            <p className='parag my-4'>There is a private bathroom with bidet in all units, along with a hairdryer and free toiletries.
                            </p>
                            <p className='parag'>The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental service and a
                                car rental service are available at the accommodation, while cycling can be enjoyed nearby.
                            </p>
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Amenities</h2>
                            <p className='parag my-4'>About the propertys amenities and services</p>
                            <hr className='w-25 my-4' />

                            <div className='d-flex align-items-center row mt-3 py-3 flex-wrap'>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450 ">
                                        key
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>la-key</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450 ">
                                        shopping_cart
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>luggage-cart</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450 ">
                                        shower
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>la-shower</span>
                                </span>
                            
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450 ">
                                        person
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>guests</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        bed
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>beds</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        bathtub
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>baths</span>
                                </span>
                            
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        smoking_rooms
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>smoking</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        ac_unit
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>snowflake</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        spa
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>la-spa</span>
                                </span>
                            
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        pool
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>swimming-pool</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        tv
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>la-tv</span>
                                </span>
                                <span className='d-flex align-items-center col-lg-4 col-6 mb-3'>
                                    <span className="material-symbols-outlined me-2 parag fs-4 font-14-450">
                                        luggage
                                    </span>
                                    <span className='parag fs-6 mx-1 font-14-450'>suitcase</span>
                                </span>
                            </div>
                            <hr className='w-25 my-5' />
                            <div></div>
                            <div className='my-4'>
                                <span className=' p-3 border parag cursor-p rounded-25p'>View more 20 amenities</span>
                            </div>
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Room Rates</h2>
                            <p className='parag my-4'>Prices may increase on weekends or holidays</p>
                            <hr className='w-25 my-4' />

                            <div className='d-flex py-3 px-2 bg-light rounded'>
                                <span className='parag'>Monday - Thursday</span>
                                <span className='ms-auto parag'>$199</span>
                            </div>
                            <div className='d-flex py-3 px-2  rounded'>
                                <span className='parag'>Monday - Thursday</span>
                                <span className='ms-auto parag'>$199</span>
                            </div>
                            <div className='d-flex py-3 px-2 bg-light rounded'>
                                <span className='parag'>Friday - Sunday</span>
                                <span className='ms-auto parag'>$229</span>
                            </div>
                            <div className='d-flex py-3 px-2  rounded'>
                                <span className='parag'>Rent by month</span>
                                <span className='ms-auto parag'>-8.5%</span>
                            </div>
                            <div className='d-flex py-3 px-2 bg-light rounded'>
                                <span className='parag'>Min number of nights</span>
                                <span className='ms-auto parag'>1 night</span>
                            </div>
                            <div className='d-flex py-3 px-2  rounded'>
                                <span className='parag'>Max number of nights</span>
                                <span className='ms-auto parag'>90 nights</span>
                            </div>
                        </section>
                        {/* calender */}
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Availability</h2>
                            <p className='parag my-4'>Prices may increase on weekends or holidays</p>
                            <hr className='w-25 my-4' />
                            <div className='row'>
                                <div className='col-lg-6 col-md-12 mb-4 border'>
                                    <Calendar onChange={onChange} value={value} className="me-5" />
                                </div>
                                <div className='col-lg-6 col-md-12 mb-4 border'>
                                    <Calendar onChange={secondOnChange} value={secondValue} />
                                </div>
                            </div>
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Host Information</h2>

                            <div className=" d-flex  align-items-center my-3" >
                                <img src={`/img/q5.jpg`} className="card-img-top rounded-50 me-2" alt="..." style={{ width: 50, height: 50 }} />
                                <div>
                                    <h5 className="p-bold p-2"> Kevin Francis</h5>
                                    <div className='d-flex'>
                                        <span className="material-symbols-outlined text-warning">
                                            star
                                        </span>
                                        <div className='parag'>4.4(153) . 12 places</div>
                                    </div>

                                </div>
                            </div>
                            <p className='parag pe-5 my-3'>Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides accommodation,
                                an outdoor swimming pool, a bar, a shared lounge, a garden and barbecue facilities...
                            </p>
                            <div className=''>
                                <span className='d-flex align-items-center my-3'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        calendar_today
                                    </span>
                                    <span className='parag fs-6 mx-1'>joined in march 2016</span>
                                </span>
                                <span className='d-flex align-items-center  my-3'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        comment
                                    </span>
                                    <span className='parag fs-6 mx-1'>respanse rate-100%</span>
                                </span>
                                <span className='d-flex align-items-center  my-3'>
                                    <span className="material-symbols-outlined me-1 parag ">
                                        schedule
                                    </span>
                                    <span className='parag fs-6 mx-1'>fast respanse - within a few hours</span>
                                </span>
                            </div>
                            <hr className='w-25 ' />
                            <div className='text-white'>.</div>
                            <div className='my-2'>
                                <span className=' px-3 py-2 border cursor-p rounded-25p'>See host profile</span>
                            </div>
                        </section>
                        {/* comments */}
                        <Reviews />
                        {/* end comments */}
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Availability</h2>
                            <p className='parag my-4'>Prices may increase on weekends or holidays</p>
                            <hr className='w-25 my-4' />
                            <Mapp />
                        </section>
                        <section className='p-4 my-5 border rounded-25p'>
                            <h2 className='h2 my-3'>Things to know</h2>
                            <hr className='w-25 my-4' />
                            <p className='h5 my-3'>Cancellation policy</p>

                            <p className='parag my-3 pe-3'>Refund 50% of the booking value when customers cancel the room within
                                48 hours after successful booking and 14 days before the check-in time.
                                Then, cancel the room 14 days before the check-in time
                                , get a 50% refund of the total amount paid (minus the service fee).
                            </p>
                            <hr className='w-25 my-4' />
                            <p className='h5 my-3'>Check-in time</p>

                            <div className='d-flex p-3 px-2 bg-light rounded font-14-450'>
                                <span className='parag'>Check-in</span>
                                <span className='ms-auto parag'>08:00 am - 12:00 am</span>
                            </div>
                            <div className='d-flex p-3 px-2 rounded font-14-450'>
                                <span className='parag'>Check-out</span>
                                <span className='ms-auto parag'>02:00 pm - 04:00 pm</span>
                            </div>
                            <hr className='w-25 my-4' />
                            <ul className='px-3'>
                                <p className='h5 my-3'>Special Note</p>
                                <li className='parag pe-3 my-3  ' style={{ listStyle: "disc" }}>Ban and I will work together to keep the landscape and environment green and clean
                                    by not littering, not using stimulants and respecting people around.
                                </li>
                                <li className='parag pe-3' style={{ listStyle: "disc" }}>Do not sing karaoke past 11:30</li>
                            </ul>
                        </section>
                    </div>
                    {/* sticky reserve form  */}
                    <div className='col-lg-4 col-xs-12 '>
                        <div className='basket-box-position-sticky'>
                            <section className='p-4 my-5 border rounded-25p '>
                                <div className='p-2 d-flex align-items-center mb-3'>
                                    <div className="p-bold h3">${info?.price}</div>
                                    <div className='small parag me-auto'>/night</div>
                                    <div><span className="material-symbols-outlined text-warning">
                                        star
                                    </span></div>
                                    <div className='mx-1 p-bold'>4.4</div>
                                    <div className='small parag'>(153)</div>
                                </div>
                                <div className='border rounded-25p mx-1'>
                                    <div className='row'>
                                        <div className='col-xxl-6 col-xl-12 col-lg-12 col-sm-6 col-xs-12 col-12'>
                                            <div className='d-flex p-3 '>
                                                <span className="material-symbols-outlined parag fs-2 me-3">
                                                    calendar_today
                                                </span>
                                                <div className=' position-relative'>
                                                    <p>{`${checkInDay}/${checkInMonth}/${checkInYear}`}</p>
                                                    {/* <input name="checkIn" onChange={e => onchange(e.target.value)} value={value}
                                                        type="date" className='form-control border-none place check-in' /> */}
                                                    <p className='parag p-2'>Check in</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 col-xs-12 col-6'>
                                            <div className='d-flex p-3 '>
                                                <span className="material-symbols-outlined parag fs-2 me-3">
                                                    calendar_today
                                                </span>
                                                <div className=' position-relative'>
                                                    <p>{`${checkOutDay}/${checkOutMonth}/${checkOutYear}`}</p>

                                                    {/* <input name="checkOut" onChange={changeHandler}
                                                        type="date" className='form-control border-none place check-out' /> */}
                                                    <p className='parag p-2'>Check out</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='border-top position-relative cursor-p'>
                                        <div onClick={(e) => {
                                            e.stopPropagation()
                                            setShowGuestsModal(true)
                                        }}
                                            className='d-flex px-3 py-1 align-items-center'>
                                            <span className="material-symbols-outlined parag fs-2 ">
                                                person_add
                                            </span>
                                            <div>
                                                <div className='form-control border-none p-bold' >{count + count1 + count2} Guests</div>
                                                <p className='parag cursor-p p-2 '>Add guests</p>
                                            </div>
                                        </div>
                                        {showGuestsModal &&
                                            <div className='position-absolute bg-white shadow rounded-25p top-100 left-0 z-2' style={{ width: 350 }}>
                                                <div className='d-flex align-items-center px-3 py-2'>
                                                    <div>
                                                        <p>Adults</p>
                                                        <p className='small parag'>Ages 13 or above</p>
                                                    </div>
                                                    <Counter defaultCount={2} className="ms-auto" />
                                                </div>
                                                <div className='d-flex align-items-center px-3 py-2'>
                                                    <div>
                                                        <p>Children</p>
                                                        <p className='small parag'>Ages 2–12</p>
                                                    </div>
                                                    <Counter defaultCount={3} className="ms-auto" />
                                                </div>
                                                <div className='d-flex align-items-center px-3 py-2'>
                                                    <div>
                                                        <p>Infants</p>
                                                        <p className='small parag'>Ages 0–2</p>
                                                    </div>
                                                    <Counter defaultCount={1} className="ms-auto" />
                                                </div>

                                            </div>}
                                    </div>
                                </div>
                                {((checkOutValue.getTime() - checkInValue.getTime()) / 86400000) > 0 ? (
                                    <div className='d-flex align-items-center p-2 mt-3'>
                                        <span className=' parag'>${info?.price} x {((checkOutValue.getTime() - checkInValue.getTime()) / 86400000) || 0} night</span>
                                        <span className=' ms-auto parag '>${(info?.price * (checkOutValue.getTime() - (checkInValue.getTime() || 1)) / 86400000) || 0}</span>
                                    </div>) : (
                                    <div className='d-flex align-items-center p-2 mt-3'>
                                        <span className=' parag'>0 night</span>
                                        <span className=' ms-auto parag '>0$</span>
                                    </div>
                                )}
                                <div className='d-flex align-items-center p-2'>
                                    <span className=' parag'>Service charge</span>
                                    <span className=' ms-auto parag '>$30</span>
                                </div>
                                <hr className='' />
                                {((checkOutValue.getTime() - checkInValue.getTime()) / 86400000) > 0 ? (
                                    <div className='d-flex align-items-center p-2'>
                                        <span className='p-bold '>Total</span>
                                        <span className=' ms-auto p-bold '>${((info?.price * (checkOutValue.getTime() - checkInValue.getTime()) / 86400000) + 30) || 0}</span>
                                    </div>
                                ) : (
                                    <div className='d-flex align-items-center p-2'>
                                        <span className='p-bold '>Total</span>
                                        <span className=' ms-auto p-bold '>$0</span>
                                    </div>
                                )}
                                <div>
                                    <button onClick={reserveHandler}
                                        className='btn btn-primary w-100 my-3 rounded-25p'>Reserve</button>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className='text-center bg-personal py-3'>
                        <SectionHeader title="Explore by types of stays" subTitle="Explore houses based on 10 types of stays" />
                        <SliderExplore />
                    </div>
                    <Newsletter />
                </div>
            </div>
            <Footer />
        </div>
    )
}
