import React, { useEffect } from 'react'

export default function Pagination( filterPagination, setFilterPagination, col, page ) {

    useEffect(() => {
        let filterArrey = filterPagination.slice((page * col) - col, page * col)
        setFilterPagination(filterArrey)
    }, [page , filterPagination , col])

    return [filterPagination]

}
