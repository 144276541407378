import React, { useEffect, useState } from 'react'
import AuthorCard from '../../Components/Cards/AuthorCard'
import CenterCard from '../../Components/Cards/CenterCard'
import NearCard from '../../Components/Cards/NearCard'
import Header from '../../Components/Header/Header'
import ImageSection from '../../Components/ImageSection/ImageSection'
import MainDisplay from '../../Components/MainDisplay/MainDisplay'
import Newsletter from '../../Components/Newsletter/Newsletter'
import Places from '../../Components/Places/Places'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import Slider1 from '../../Components/Slider1/Slider1'
import Slider4 from '../../Components/Slider4/Slider4'
import Slider3 from '../../Components/Slider3/Slider3'
import Videos from '../../Components/Videos/Videos'
import Footer from '../../Components/Footer/Footer'

export default function Home() {

  const [users, setUsers] = useState([])
  const [stays, setStays] = useState([])
  const [nearbys, setNearbys] = useState([
    { id: "1",img : "q6.jpg" , title: "Paris" },
    { id: "2",img : "q2.jpg" , title: "London" },
    { id: "3",img : "q3.jpg" , title: "Utah" },
    { id: "4",img : "q4.jpg" , title: "Prag" },
  ])


  const getAllUsers = async () => {

    const res = await fetch("https://airbnb-api-six.vercel.app/api/users", {
      method: "POST",
      body: JSON.stringify({ code: 200 })
    })
    const data = await res.json()
    setUsers(data.users)
  }

  const getAllStays = async () => {

    const res = await fetch("https://airbnb-api-six.vercel.app/api/posts" , {
      method : "POST"
    })
    const data = await res.json()
    setStays(data.posts)
  }


  useEffect(() => {
    getAllUsers()
    getAllStays()
  }, [])


  return (
    <div>
      <Header />
      <MainDisplay img="h2.png">
        <div className='col-md-6 col-sm-12'>
          <h2 className='h0 mt-5 font-size-header'>Hotel, car & <br />experiences</h2>
          <p className='my-5 lead p-line-height pe-5' id="C4">
            Accompanying us, you have a trip full of experiences.
            With  Chisfis, booking accommodation, resort villas, hotels
          </p>
          <button className='btn btn-primary btn-lg mb-5 rounded-25p'><a href="#C4">Strat your search</a></button>
        </div>
      </MainDisplay>
      <SectionHeader title="Best of city" subTitle="Injoy in the world" />
      <Slider1 />
      <ImageSection />
      <div className='bg-light rounded py-3'>
        <SectionHeader title="Featured places to stay" subTitle="Popular places to stay that Chisfis recommends for you" />
        <Places stays={stays} />
      </div>
      <div className='text-center' >
        <SectionHeader title="How it work" subTitle="Keep calm & travel on" />
      </div>
      <div className='container'>
        <div className='row' >
          <CenterCard img="c1.png" />
          <CenterCard img="c2.png" />
          <CenterCard img="c3.png" />
        </div>
      </div>
      <div className='text-center bg-personal py-3'>
        <SectionHeader title="Suggestions for discovery" subTitle="Popular places to stay that Chisfis recommends for you" />
        <Slider3 />
      </div>
      <Newsletter />
      <div className='text-center bg-personal py-3'>
        <SectionHeader title="Top 10 author of the month" subTitle="Rating based on customer reviews" />
        <div className='container '>
          <div className='row'>
            {users.map((user, index) => {
              if (index < 12) {
                return <AuthorCard key={user._id}  {...user} />
              }
            })}
          </div>
        </div>
      </div>
      <div className='text-center bg-light py-3'>
        <SectionHeader title="Explore nearby" subTitle="Discover great places near where you live" />
        <div className='container '>
          <div className='row'>
            {nearbys.map(city => (
              <NearCard key={city.id} img="q1.jpg" {...city} />
            ))}
          </div>
        </div>
      </div>
      <div className='container py-5 my-3'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 text-start d-flex align-items-center justify-content-center'>
            <div className=''>
              <img src='/img/logo.png' className='w-25' />
              <h2 className='h2 my-4'>Why did you choose us?</h2>
              <p className='parag my-4'>Accompanying us, you have a trip full of experiences.
                With Chisfis, booking accommodation, resort villas, hotels, private houses,
                apartments... becomes fast, convenient and easy.
              </p>
              <button className='btn btn-primary rounded-50p p-2'>Become an author</button>
            </div>
          </div>
          <div className='col-md-6 col-sm-12'>
            <img src='/img/p1.png' />
          </div>
        </div>
      </div>

      <Videos />

      <div className='text-center bg-light py-3'>
        <SectionHeader title="Good news from far away" subTitle="Let's see what people think of Chisfis" />
        <div className='container'>
          <Slider4 />
        </div>
      </div>
      <Footer />
    </div>
  )
}
