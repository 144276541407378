import React from 'react'

export default function EmailSection() {
    return (
        <div className="input-group my-5 w-75 border rounded-50p ps-3">
            <input type="text" className="form-control border-none" placeholder="Enter your email" aria-label="Recipient's username" aria-describedby="button-addon2" />
            <button className="btn  border-none" type="button" id="button-addon2">
                <span className="material-symbols-outlined bg-primary text-white p-2 rounded-50p ">
                    east
                </span>
            </button>
        </div>
    )
}
