import React from 'react'

export default function CommentBox() {
    return (
        <div>
            <h3 className='h4 my-3'>Responses(14)</h3>
            <textarea className='form-control my-4'></textarea>
            <div className='my-3'>
                <button className='btn btn-primary btn-lg me-2 rounded-25p'>Submit</button>
                <button className='btn btn-outline-secondary btn-lg rounded-25p'>cancel</button>
            </div>
            <div className='d-flex mt-5 mb-3'>
                <img src='/img/f3.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-none-500" />
                <div className='border rounded-25p p-3'>
                    <div className='d-flex align-items-center'>
                        <img src='/img/f3.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-block-500" />
                        <span className='p-bold'>Founcher Natali</span>
                        <span className='parag'>. May 20,2022</span>
                    </div>
                    <p className='parag my-3'>
                        In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non,
                        pretium quis, lectus.
                        Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.
                    </p>
                    <button className='btn bg-personal rounded-25p '>Reply</button>
                </div>
            </div>
            <div className='d-flex ps-5'>
                <img src='/img/f4.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-none-500" />
                <div className='border rounded-25p p-3'>
                    <div className='d-flex align-items-center'>
                        <img src='/img/f4.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-block-500" />
                        <span className='p-bold'>Minoli Natali</span>
                        <span className='parag'>. May 20,2022</span>
                    </div>
                    <p className='parag my-3 font-14-450'>
                        In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non,
                        pretium quis, lectus.
                        Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.
                    </p>
                    <button className='btn bg-personal rounded-25p '>Reply</button>
                </div>
            </div>
            <div className='d-flex mt-5 mb-3'>
                <img src='/img/f3.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-none-500" />
                <div className='border rounded-25p p-3'>
                    <div className='d-flex align-items-center'>
                        <img src='/img/f3.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-block-500" />
                        <span className='p-bold'>David Natali</span>
                        <span className='parag'>. May 20,2022</span>
                    </div>
                    <p className='parag my-3 '>
                        In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non,
                        pretium quis, lectus.
                        Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.
                    </p>
                    <button className='btn bg-personal rounded-25p '>Reply</button>
                </div>
            </div>
            <div className='d-flex ps-5'>
                <img src='/img/f4.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-none-500" />
                <div className='border rounded-25p p-3'>
                    <div className='d-flex align-items-center'>
                        <img src='/img/f4.jpg' style={{ width: 40, height: 40 }} className="rounded-50p me-3 d-block-500" />
                        <span className='p-bold'>Founcher Natali</span>
                        <span className='parag'>. May 20,2022</span>
                    </div>
                    <p className='parag my-3 font-14-450'>
                        In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non,
                        pretium quis, lectus.
                        Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.
                    </p>
                    <button className='btn bg-personal rounded-25p '>Reply</button>
                </div>
            </div>
        </div>
    )
}
